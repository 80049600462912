import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { OpportunityList } from '../services/opportunity.service';
import { OpportunityFormData } from '../interfaces/opportunity.interface';

export const OpportunityActions = createActionGroup({
  source: 'Opportunity Component',
  events: {
    list: props<OpportunityList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    opportunity: props<{ id: number, dealType?: number }>(),
    opportunitySuccess: props<{ data: any }>(),
    opportunityFail: props<{ error: any }>(),
    categories: props<{ limit: number; offset: number }>(),
    categoriesSuccess: props<{ data: any }>(),
    categoriesFail: props<{ error: any }>(),
    suppliers: props<{ limit: number; offset: number; name?: string }>(),
    suppliersSuccess: props<{ data: any }>(),
    suppliersFail: props<{ error: any }>(),
    malls: props<{ name?: string }>(),
    mallsSuccess: props<{ data: any }>(),
    mallsFail: props<{ error: any }>(),
    tags: props<{ name?: string }>(),
    tagsSuccess: props<{ data: any }>(),
    tagsFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<OpportunityFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    duplicate: props<Partial<OpportunityFormData>>(),
    duplicateSuccess: props<{ data: any }>(),
    duplicateFail: props<{ error: any }>(),
    update: props<Partial<OpportunityFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
    image: props<{
      files: {
        type: string;
        file: File;
        name: string;
      }[];
      folder: string;
      galleryId: number;
    }>(),
    imageSuccess: props<{ data: string[] }>(),
    imageFail: props<{ error: any }>(),

    opportunitiesDropdown: props<{ limit: number; offset: number, dealType?: number, name?: string, supplierId?: number }>(),
    opportunitiesDropdownSuccess: props<{ data: any }>(),
    opportunitiesDropdownFail: props<{ error: any }>(),
  },
});
