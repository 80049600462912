// loader.component.ts
import { Component,ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './loading.state'; // Update this path to your AppState definition
import { selectIsLoading } from './loading.selectors'; // Update this path to your selector file
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common'; // Import CommonModule

@Component({
  selector: 'app-loader',
  imports: [NgIf, CommonModule],
  template: `
  <div class="v-page-layout-simple">
    <div class="loader-overlay" *ngIf="loading$ | async">
    <img src="https://storage.googleapis.com/myofer-cms-prod-bucket/loader2.gif" width="250px" height="250px" alt="Loading..." class="loader" />
    </div>
  </div>
  `,
  styles: [
    `
      .loader-overlay {
        position: absolute; /* Make it relative to the parent container */
        top: 50%;
        left: 50%;
        width: 700px; /* Adjust size as needed for the circle */
        height: 700px; /* Make height equal to width to maintain a perfect circle */
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        border-radius: 50%; /* This makes it a circle */
        transform: translate(-50%, -50%); /* Center the circle in the parent container */
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); /* Optional: add a shadow to make it pop */
      }

      .loader {
        width: 250px; /* Adjust size as needed */
        height: 150px; /* Set height equal to width for consistency */
        border-radius: 50%; /* Make sure the loader itself is also circular */
      }

    `
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent {
  loading$ = this.store.select(selectIsLoading);

  constructor(private store: Store<AppState>) {}
}
