import { createReducer, on } from '@ngrx/store';

import { BannersActions } from './banners.actions';
import { BannersState } from '../interfaces/banners.interface';

const initialState: BannersState = {
  banners: null,
  loading: false,
  error: null,
  banner: null,
  bannerLoading: false,
  bannerError: null,
  deleteLoading: false,
  deleteBanners: false,
  deleteError: null,
  createLoading: false,
  createBanners: false,
  createError: null,
  updateLoading: false,
  updateBanners: false,
  updateError: null,
  slots: null,
  slotsLoading: false,
  slotsError: null,
  malls: null,
  mallsLoading: false,
  mallsError: null,
};

export const bannersReducer = createReducer(
  initialState,
  on(BannersActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(BannersActions.listSuccess, (state, { data }) => ({
    ...state,
    banners: data,
    loading: true,
    error: null,
  })),
  on(BannersActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(BannersActions.banner, (state) => ({
    ...state,
    banner: null,
    bannerLoading: false,
    bannerError: null,
  })),
  on(BannersActions.bannerSuccess, (state, { data }) => ({
    ...state,
    banner: data,
    bannerLoading: true,
    bannerError: null,
  })),
  on(BannersActions.bannerFail, (state, { error }) => ({
    ...state,
    banner: null,
    bannerLoading: false,
    bannerError: error,
  })),
  on(BannersActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteBanners: false,
  })),
  on(BannersActions.deleteSuccess, (state) => ({
    ...state,
    deleteBanners: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(BannersActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteBanners: false,
  })),
  on(BannersActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createBanners: false,
  })),
  on(BannersActions.createSuccess, (state) => ({
    ...state,
    createBanners: true,
    createLoading: true,
    createError: null,
  })),
  on(BannersActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createBanners: false,
  })),
  on(BannersActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateBanners: false,
  })),
  on(BannersActions.updateSuccess, (state) => ({
    ...state,
    updateBanners: true,
    updateLoading: true,
    updateError: null,
  })),
  on(BannersActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateBanners: false,
  })),
  on(BannersActions.slots, (state) => ({
    ...state,
    slots: null,
    slotsLoading: false,
    slotsError: null,
  })),
  on(BannersActions.slotsSuccess, (state, { data }) => ({
    ...state,
    slots: data,
    slotsLoading: true,
    slotsError: null,
  })),
  on(BannersActions.slotsFail, (state, { error }) => ({
    ...state,
    slots: null,
    slotsLoading: false,
    slotsError: error,
  })),
  on(BannersActions.malls, (state) => ({
    ...state,
    malls: null,
    mallsLoading: false,
    mallsError: null,
  })),
  on(BannersActions.mallsSuccess, (state, { data }) => ({
    ...state,
    malls: data,
    mallsLoading: true,
    mallsError: null,
  })),
  on(BannersActions.mallsFail, (state, { error }) => ({
    ...state,
    malls: null,
    mallsLoading: false,
    mallsError: error,
  })),
);

export const bannersFeatureKey = 'banners';
