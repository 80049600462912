import { createReducer, on } from '@ngrx/store';
import { ContentPagesActions } from './content-pages.actions';
import { ContentPageState } from '../interfaces/content-pages.interface';

const initialState: ContentPageState = {
  contentPages: null,
  loading: false,
  error: null,
  contentPage: null,
  contentPageLoading: false,
  contentPageError: null,
  deleteLoading: false,
  deleteContentPage: false,
  deleteError: null,
  createLoading: false,
  createContentPage: false,
  createError: null,
  createSuccess: false,
  updateLoading: false,
  updateContentPage: false,
  updateError: null,
  updateSuccess: false,
};

export const contentPageReducer = createReducer(
  initialState,

  // 🔹 Fix: Set `loading: true` when fetching the receipt list
  on(ContentPagesActions.list, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // 🔹 Fix: Set `loading: false` when list fetch is successful
  on(ContentPagesActions.listSuccess, (state, { data }) => ({
    ...state,
    contentPages: data,
    loading: false, // Set loading to false
    error: null,
  })),

  on(ContentPagesActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ContentPagesActions.contentPage, (state) => ({
    ...state,
    receiptLoading: true, // Fix: Set loading to true while fetching
    contentPage: null,
    receiptError: null,
  })),

  on(ContentPagesActions.contentPageSuccess, (state, { data }) => ({
    ...state,
    contentPage: data,
    receiptLoading: false, // Set loading to false
    receiptError: null,
  })),

  on(ContentPagesActions.contentPageFail, (state, { error }) => ({
    ...state,
    contentPage: null,
    receiptLoading: false,
    receiptError: error,
  })),

  on(ContentPagesActions.cancelContentPage, (state) => ({
    ...state,
    deleteLoading: true, // Fix: Set deleteLoading to true
    deleteError: null,
    deleteReceipt: false,
  })),

  on(ContentPagesActions.cancelSuccess, (state) => ({
    ...state,
    deleteReceipt: true,
    deleteLoading: false, // Fix: Set deleteLoading to false
    deleteError: null,
  })),

  on(ContentPagesActions.cancelFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteReceipt: false,
  })),

  on(ContentPagesActions.createContentPage, (state) => ({
    ...state,
    createLoading: true, // Fix: Set createLoading to true
    createError: null,
    createReceipt: false,
  })),

  on(ContentPagesActions.createSuccess, (state) => ({
    ...state,
    createReceipt: true,
    createLoading: false, // Fix: Set createLoading to false
    createError: null,
  })),

  on(ContentPagesActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createReceipt: false,
  })),

  on(ContentPagesActions.updateContentPage, (state) => ({
    ...state,
    updateLoading: true, // Fix: Set updateLoading to true
    updateError: null,
    updateReceipt: false,
  })),

  on(ContentPagesActions.updateSuccess, (state) => ({
    ...state,
    updateReceipt: true,
    updateLoading: false, // Fix: Set updateLoading to false
    updateError: null,
  })),

  on(ContentPagesActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateReceipt: false,
  }))
);

// 🔹 Ensure this key matches StoreModule.forFeature('receipts', receiptReducer)
export const contentPageKey = 'contentPages';
