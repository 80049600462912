// client.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { EntityActions } from './client.actions';
import { EntityState } from '../interfaces/client.interface';

const initialState: EntityState = {
  rows: null,
  loading: false,
  error: null,
  entity: null,
  entityLoading: false,
  entityError: null,
  updateLoading: false,
  updateEntity: false,
  updateError: null,
  subscriptionEntity: null,
  subscriptionEntityLoading: false,
  subscriptionEntityError: null,
};

export const clientReducer = createReducer(
  initialState,
  on(EntityActions.list, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(EntityActions.listSuccess, (state, { data }) => ({
    ...state,
    rows: data,
    loading: false,
    error: null,
  })),
  on(EntityActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(EntityActions.entity, (state) => ({
    ...state,
    entity: null,
    entityLoading: true,
    entityError: null,
  })),
  on(EntityActions.entitySuccess, (state, { data }) => ({
    ...state,
    entity: data,
    entityLoading: false,
    entityError: null,
  })),
  on(EntityActions.entityFail, (state, { error }) => ({
    ...state,
    entity: null,
    entityLoading: false,
    entityError: error,
  })),
  on(EntityActions.update, (state) => ({
    ...state,
    updateLoading: true,
    updateError: null,
    updateEntity: false,
  })),
  on(EntityActions.updateSuccess, (state) => ({
    ...state,
    updateEntity: true,
    updateLoading: false,
    updateError: null,
  })),
  on(EntityActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateEntity: false,
  })),
  on(EntityActions.subscriptionEntity, (state) => ({
    ...state,
    subscriptionEntity: null,
    subscriptionEntityLoading: true,
    subscriptionEntityError: null,
  })),
  on(EntityActions.subscriptionEntitySuccess, (state, { data }) => ({
    ...state,
    rows: data,
    loading: false,
    error: null,
  })),
  on(EntityActions.subscriptionEntityFail, (state, { error }) => ({
    ...state,
    subscriptionEntity: null,
    subscriptionEntityLoading: false,
    subscriptionEntityError: error,
  })),
  on(EntityActions.updateEntity, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateEntity: false,
  })),
  on(EntityActions.updateEntitySuccess, (state) => ({
    ...state,
    updateEntity: true,
    updateLoading: false,
    updateError: null,
  })),
  on(EntityActions.updateEntityFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateEntity: false,
  })),
);

export const clientKey = 'client';
