import { createReducer, on } from '@ngrx/store';

import { OpportunityActions } from './opportunity.actions';
import { OpportunityState } from '../interfaces/opportunity.interface';

const initialState: OpportunityState = {
  opportunities: null,
  loading: false,
  error: null,
  opportunity: null,
  opportunityLoading: false,
  opportunityError: null,
  categories: null,
  categoriesLoading: false,
  categoriesError: null,
  suppliers: null,
  suppliersLoading: false,
  suppliersError: null,
  malls: null,
  mallsLoading: false,
  mallsError: null,
  tags: null,
  tagsLoading: false,
  tagsError: null,
  deleteLoading: false,
  deleteOpportunity: false,
  deleteError: null,
  createLoading: false,
  createOpportunity: null,
  createError: null,
  duplicateLoading: false,
  duplicateOpportunity: null,
  duplicateError: null,
  updateLoading: false,
  updateOpportunity: null,
  updateError: null,
  image: null,
  imageLoading: false,
  imageError: null,
  imageSuccess: false,
  opportunitiesDropdown: null,
  opportunitiesDropdownLoading: false,
  opportunitiesDropdownError: null,
};

export const opportunityReducer = createReducer(
  initialState,
  on(OpportunityActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(OpportunityActions.listSuccess, (state, { data }) => ({
    ...state,
    opportunities: data,
    loading: true,
    error: null,
  })),
  on(OpportunityActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(OpportunityActions.opportunity, (state) => ({
    ...state,
    opportunity: null,
    opportunityLoading: false,
    opportunityError: null,
  })),
  on(OpportunityActions.opportunitySuccess, (state, { data }) => ({
    ...state,
    opportunity: data,
    opportunityLoading: true,
    opportunityError: null,
  })),
  on(OpportunityActions.opportunityFail, (state, { error }) => ({
    ...state,
    opportunity: null,
    opportunityLoading: true,
    opportunityError: error,
  })),
  on(OpportunityActions.categories, (state) => ({
    ...state,
    categories: null,
    categoriesLoading: false,
    categoriesError: null,
  })),
  on(OpportunityActions.categoriesSuccess, (state, { data }) => ({
    ...state,
    categories: data,
    categoriesLoading: true,
    categoriesError: null,
  })),
  on(OpportunityActions.categoriesFail, (state, { error }) => ({
    ...state,
    categories: null,
    categoriesLoading: false,
    categoriesError: error,
  })),
  on(OpportunityActions.suppliers, (state) => ({
    ...state,
    suppliers: null,
    suppliersLoading: false,
    suppliersError: null,
  })),
  on(OpportunityActions.suppliersSuccess, (state, { data }) => ({
    ...state,
    suppliers: data,
    suppliersLoading: true,
    suppliersError: null,
  })),
  on(OpportunityActions.suppliersFail, (state, { error }) => ({
    ...state,
    suppliers: null,
    suppliersLoading: false,
    suppliersError: error,
  })),
  on(OpportunityActions.malls, (state) => ({
    ...state,
    malls: null,
    mallsLoading: false,
    mallsError: null,
  })),
  on(OpportunityActions.mallsSuccess, (state, { data }) => ({
    ...state,
    malls: data,
    mallsLoading: true,
    mallsError: null,
  })),
  on(OpportunityActions.mallsFail, (state, { error }) => ({
    ...state,
    malls: null,
    mallsLoading: false,
    mallsError: error,
  })),
  on(OpportunityActions.tags, (state) => ({
    ...state,
    tags: null,
    tagsLoading: false,
    tagsError: null,
  })),
  on(OpportunityActions.tagsSuccess, (state, { data }) => ({
    ...state,
    tags: data,
    tagsLoading: true,
    tagsError: null,
  })),
  on(OpportunityActions.tagsFail, (state, { error }) => ({
    ...state,
    tags: null,
    tagsLoading: false,
    tagsError: error,
  })),
  on(OpportunityActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteOpportunity: false,
  })),
  on(OpportunityActions.deleteSuccess, (state) => ({
    ...state,
    deleteOpportunity: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(OpportunityActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteOpportunity: false,
  })),
  on(OpportunityActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createOpportunity: null,
  })),
  on(OpportunityActions.createSuccess, (state, { data }) => ({
    ...state,
    createOpportunity: data,
    createLoading: true,
    createError: null,
  })),
  on(OpportunityActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createOpportunity: null,
  })),
  on(OpportunityActions.duplicate, (state) => ({
    ...state,
    duplicateLoading: false,
    duplicateError: null,
    duplicateOpportunity: null,
  })),
  on(OpportunityActions.duplicateSuccess, (state, { data }) => ({
    ...state,
    duplicateOpportunity: data,
    duplicateLoading: true,
    duplicateError: null,
  })),
  on(OpportunityActions.duplicateFail, (state, { error }) => ({
    ...state,
    duplicateLoading: false,
    duplicateError: error,
    duplicateOpportunity: null,
  })),
  on(OpportunityActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateOpportunity: null,
  })),
  on(OpportunityActions.updateSuccess, (state, { data }) => ({
    ...state,
    updateOpportunity: data,
    updateLoading: true,
    updateError: null,
  })),
  on(OpportunityActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateOpportunity: null,
  })),
  on(OpportunityActions.image, (state) => ({
    ...state,
    image: null,
    imageLoading: false,
    imageError: null,
    imageSuccess: false,
  })),
  on(OpportunityActions.imageSuccess, (state, { data }) => ({
    ...state,
    imageSuccess: true,
    image: data,
    imageLoading: true,
    imageError: null,
  })),
  on(OpportunityActions.imageFail, (state, { error }) => ({
    ...state,
    image: null,
    imageLoading: false,
    imageError: error,
    imageSuccess: false,
  })),

  on(OpportunityActions.opportunitiesDropdown, (state) => ({
    ...state,
    opportunitiesDropdown: null,
    opportunitiesDropdownLoading: false,
    opportunitiesDropdownError: null,
  })),
  on(OpportunityActions.opportunitiesDropdownSuccess, (state, { data }) => ({
    ...state,
    opportunitiesDropdown: data,
    opportunitiesDropdownLoading: true,
    opportunitiesDropdownError: null,
  })),
  on(OpportunityActions.opportunitiesDropdownFail, (state, { error }) => ({
    ...state,
    opportunitiesDropdown: null,
    opportunitiesDropdownLoading: false,
    opportunitiesDropdownError: error,
  })),
);

export const opportunityFeatureKey = 'opportunity';
