import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ContentPagesActions } from './content-pages.actions';

import { map, mergeMap } from 'rxjs/operators';
import { ContentPageList, ContentPagesService } from '../services/content-pages.service';
import { Tb_ContentPage } from '../interfaces/content-pages.interface';

@Injectable()
export class ContentPageEffects {
  constructor(
    private actions$: Actions,
    private contentPageService: ContentPagesService,
  ) {}

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentPagesActions.list),
      map(
        ({ type: _type, ...params }: ContentPageList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.contentPageService
          .list(params)
          .pipe(map((data) => ContentPagesActions.listSuccess({ data })));
      }),
    ),
  );

  getContentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentPagesActions.contentPage),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.contentPageService
          .getContentPage(params)
          .pipe(map((data) => ContentPagesActions.contentPageSuccess({ data })));
      }),
    ),
  );

  updateContentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentPagesActions.updateContentPage),
      map(({ type: _type, ...params }) => params as Partial<Tb_ContentPage>),
      mergeMap((params) => {
        return this.contentPageService
          .updateContentPage(params)
          .pipe(map((data) => ContentPagesActions.updateSuccess({ data })));
      }),
    ),
  );
}
