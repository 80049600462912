import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChainFormData } from '../interfaces/chains.interface';

export interface ChainList {
  offset: number;
  limit: number;
  active?: boolean,
  name?: string;
  id?: string;
  supplier?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChainsService {
  constructor(private http: HttpClient) {}

  public list(params: ChainList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/chains/`, {
      params: httpParams,
    });
  }

  public suppliers(params: { offset: number; limit: number; name?: string, active?: boolean, isChain?: boolean }) {
    return this.http.get(`${environment.apiUrl}/chains/suppliers`, {
      params,
    });
  }

  public getChain(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/chains/getChainById/${params.id}`,
    );
  }

  public delete(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/chains/`, { body: { ids } });
  }

  public create(params: Partial<ChainFormData>) {
    return this.http.post(`${environment.apiUrl}/chains/`, params);
  }

  public update(params: Partial<ChainFormData>) {
    return this.http.put(`${environment.apiUrl}/chains/`, { ...params });
  }
}
