import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { LpList } from '../services/lps.service';
import { LpFormData } from '../interfaces/lps.interface';

export const LpsActions = createActionGroup({
  source: 'Lps Component',
  events: {
    list: props<LpList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    lp: props<{ id: number }>(),
    lpSuccess: props<{ data: any }>(),
    lpFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<LpFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<LpFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});
