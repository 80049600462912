import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { SettingsActions } from './settings.actions';

import { map, mergeMap } from 'rxjs/operators';
import { SettingsList, SettingsService } from '../services/settings.service';
import { Tb_Settings } from '../interfaces/settings.interface';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService,
  ) {}

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.list),
      map(
        ({ type: _type, ...params }: SettingsList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.settingsService
          .list(params)
          .pipe(map((data) => SettingsActions.listSuccess({ data })));
      }),
    ),
  );

  syncData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.syncData),
      map(({ type: _type, ...params }) => params as { id: number; value: string }),
      mergeMap((params) => {
        return this.settingsService
          .syncData(params)
          .pipe(map((data) => SettingsActions.syncSuccess({ data })));
      }),
    ),
  );

  updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.updateSettings),
      map(({ type: _type, ...params }) => params as Partial<Tb_Settings>),
      mergeMap((params) => {
        return this.settingsService
          .updateSettings(params)
          .pipe(map((data) => SettingsActions.updateSuccess({ data })));
      }),
    ),
  );

  createSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingsActions.createSettings),
      map(({ type: _type, ...params }) => params as Partial<Tb_Settings>),
      mergeMap((params) => {
        return this.settingsService
          .createSettings(params)
          .pipe(map((data) => SettingsActions.createSuccess({ data })));
      }),
    ),
  );
}
