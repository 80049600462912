import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { GroupsActions } from './groups.actions';

import { map, mergeMap } from 'rxjs/operators';
import { GroupList, GroupsService } from '../services/groups.service';
import { GroupFormData } from '../interfaces/groups.interface';

@Injectable()
export class GroupsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.list),
      map(({ type: _type, ...params }: GroupList & { type: string }) => params),
      mergeMap((params) => {
        return this.groupsService
          .list(params)
          .pipe(map((data) => GroupsActions.listSuccess({ data })));
      }),
    ),
  );

  getGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.group),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.groupsService
          .getGroup(params)
          .pipe(map((data) => GroupsActions.groupSuccess({ data })));
      }),
    ),
  );

  suppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.suppliers),
      map(({ type: _type, ...params }) => params),
      mergeMap((params) => {
        return this.groupsService
          .suppliers(params)
          .pipe(map((data) => {
            return GroupsActions.suppliersSuccess({data})
          }));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.delete),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.groupsService
          .delete(param.ids)
          .pipe(map((data) => GroupsActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<GroupFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.groupsService
          .create(params as Partial<GroupFormData>)
          .pipe(map((data) => GroupsActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<GroupFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.groupsService
          .update(params as Partial<GroupFormData>)
          .pipe(map((data) => GroupsActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private groupsService: GroupsService,
  ) {}
}
