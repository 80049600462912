import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { BannersActions } from './banners.actions';

import { map, mergeMap } from 'rxjs/operators';
import { BannerList, BannersService } from '../services/banners.service';
import { BannerFormData } from '../interfaces/banners.interface';

@Injectable()
export class BannersEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.list),
      map(({ type: _type, ...params }: BannerList & { type: string }) => params),
      mergeMap((params) => {
        return this.bannersService
          .list(params)
          .pipe(map((data) => BannersActions.listSuccess({ data })));
      }),
    ),
  );

  getBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.banner),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.bannersService
          .getBanner(params)
          .pipe(map((data) => BannersActions.bannerSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.delete),
      map(({ id }: { id: number; type: string }) => ({
        id,
      })),
      mergeMap((param) => {
        return this.bannersService
          .delete(param.id)
          .pipe(map((data) => BannersActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<BannerFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.bannersService
          .create(params.params)
          .pipe(map((data) => BannersActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<BannerFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.bannersService
          .update(params as Partial<BannerFormData>)
          .pipe(map((data) => BannersActions.updateSuccess({ data })));
      }),
    ),
  );

  slots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.slots),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          limit: number;
          offset: number;
        }) => params,
      ),
      mergeMap((params) => {
        return this.bannersService
          .slots(params)
          .pipe(map((data) => BannersActions.slotsSuccess({ data })));
      }),
    ),
  );

  malls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.malls),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
        }) => params,
      ),
      mergeMap((params) => {
        return this.bannersService
          .malls(params)
          .pipe(map((data) => BannersActions.mallsSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private bannersService: BannersService,
  ) {}
}
