import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { ChainList } from '../services/chains.service';
import { ChainFormData } from '../interfaces/chains.interface';

export const ChainsActions = createActionGroup({
  source: 'Chains Component',
  events: {
    list: props<ChainList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    chain: props<{ id: number }>(),
    chainSuccess: props<{ data: any }>(),
    chainFail: props<{ error: any }>(),
    suppliers: props<{ offset: number; limit: number; name?: string; relatedId?: number, active?: boolean, isChain?: boolean }>(),
    suppliersSuccess: props<{ data: any }>(),
    suppliersFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<ChainFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<ChainFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});
