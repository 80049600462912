import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EventsActions } from './events.actions';

import { map, mergeMap } from 'rxjs/operators';
import { EventsList, EventsService } from '../services/events.service';
import { EventFormData } from '../interfaces/events.interface';

@Injectable()
export class EventsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.list),
      map(
        ({ type: _type, ...params }: EventsList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.eventsService
          .list(params)
          .pipe(map((data) => EventsActions.listSuccess({ data })));
      }),
    ),
  );

  getEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.event),
      map(({ ...params }: { id: number | undefined }) => params),
      mergeMap((params) => {
        return this.eventsService
          .getEvent(params)
          .pipe(map((data) => EventsActions.eventSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<EventFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.eventsService
          .create(params as Partial<EventFormData>)
          .pipe(map((data) => EventsActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<EventFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.eventsService
          .update(params as Partial<EventFormData>)
          .pipe(map((data) => EventsActions.updateSuccess({ data })));
      }),
    ),
  );

  uploadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.image),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          files: {
            fileType: string;
            file: File;
            index: number;
            name: string;
          }[];
          folder: string;
        }) => params,
      ),
      mergeMap((params) => {
        return this.eventsService
          .uploadImage(params)
          .pipe(map((data) => EventsActions.imageSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private eventsService: EventsService,
  ) {}
}
