import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StoreFormData, StoreUsers, Tb_Malls_Entrance_Floors } from '../interfaces/stores.interface';
import { forkJoin, of, switchMap } from 'rxjs';
import { Tb_Malls } from '../../../opportunity/interfaces/opportunity.interface';

export interface StoresList {
  offset: number;
  limit: number;
  title?: string;
  id?: string;
  supplier?: number;
  active?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  constructor(private http: HttpClient) {}

  public list(params: StoresList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/stores/`, {
      params: httpParams,
    });
  }

  public listNew(params: StoresList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/stores/storesNew`, {
      params: httpParams,
    });
  }

  public suppliers(params: { offset: number; limit: number; name?: string }) {
    return this.http.get(`${environment.apiUrl}/stores/suppliers`, {
      params,
    });
  }

  public getStore(params: { id: number | undefined }) {
    return this.http.get(
      `${environment.apiUrl}/stores/getStoreById/${params.id}`,
    );
  }

  public delete(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/stores/`, { body: { ids } });
  }

  public create(params: Partial<StoreFormData>) {
    return this.http.post(`${environment.apiUrl}/stores/`, params);
  }

  public update(params: Partial<StoreFormData>) {
    return this.http.put(`${environment.apiUrl}/stores/`, { ...params });
  }

  public deleteUser(UserID: string, MerchantID: string) {
    return this.http.delete<HttpResponse<any>>(
      `${environment.apiUrl}/stores/user`,
      {
        body: { UserID, MerchantID },
        observe: 'response',
      },
    );
  }

  public activeInactiveUser(
    UserID: string,
    MerchantID: string,
    Active: boolean,
  ) {
    return this.http.put<{ status: number }>(
      `${environment.apiUrl}/stores/activeUser`,
      {
        UserID,
        MerchantID,
        Active,
      },
    );
  }

  public changePassword(userId: string, password: string) {
    return this.http.put<{ status: number }>(
      `${environment.apiUrl}/stores/changePassword`,
      {
        userId,
        password,
      },
    );
  }

  public createUser(
    merchantName: string,
    supplierId: number,
    email: string,
    password: string,
  ) {
    return this.http.post<StoreUsers[]>(
      `${environment.apiUrl}/stores/createUser`,
      {
        merchantName,
        supplierId,
        email,
        password,
      },
    );
  }

  public malls(
  ) {
    return this.http.get<Tb_Malls>(
      `${environment.apiUrl}/unicorn/malls`
    );
  }

  public mallFloorsEntrance(id: number
  ) {
    return this.http.get<Tb_Malls_Entrance_Floors>(
      `${environment.apiUrl}/unicorn/mallsEntranceFloors`, { params: { id } }
    );
  }

  private getSignUrls(params: {
    filesType: { index: number; type: string }[];
    folder: string;
  }) {
    return this.http.post(`${environment.apiUrl}/cloudinary/signUrls`, {
      ...params,
      onlyFolder: true,
    });
  }

  private upload(
    signedFiles: {
      uploadUrl: string;
      file: File;
      api_key: string;
      timestamp: number;
      signature: string;
      relativeLink: string;
    }[],
  ) {
    const uploadObservables = signedFiles.map((signedFile) => {
      const formData = new FormData();
      formData.append('file', signedFile.file);
      formData.append('api_key', signedFile.api_key);
      formData.append('timestamp', String(signedFile.timestamp));
      formData.append('signature', signedFile.signature);
      formData.append('public_id', signedFile.relativeLink);

      return this.http.post(signedFile.uploadUrl, formData);
    });

    return forkJoin(uploadObservables);
  }

  public uploadImage({
    files,
    folder,
  }: {
    files: {
      name: string;
      fileType: string;
      file: File;
      index: number;
    }[];
    folder: string;
  }) {
    return this.getSignUrls({
      filesType: files.map((f) => ({
        index: f.index,
        type: f.fileType,
        name: f.name,
      })),
      folder,
    }).pipe(
      switchMap((res: unknown) => {
        const signedUrls = (
          res as {
            signedUrls: {
              name: string;
              uploadUrl: string;
              relativeLink: string;
              api_key: string;
              signature: string;
              timestamp: number;
            }[];
          }
        ).signedUrls;

        const signedFiles = signedUrls.map((t, index) => {
          const file = new File([files[index].file], t.name, {
            type: files[index].file.type,
          });
          return {
            ...t,
            file,
          };
        });
        return this.upload(signedFiles).pipe(
          switchMap(() => {
            return of(signedUrls.map((t) => t.relativeLink));
          }),
        );
      }),
    );
  }

  public uploadDocuments(params: { supplierId: number; files: string[] }) {
    return this.http.post<{name: string, signedUrl: string, relativeLink: string}[]>(`${environment.apiUrl}/stores/uploadDocuments`, {
      params,
    });
  }

  public downloadSignUrl(params: { supplier_id: number; path: string }) {
    return this.http.post<{signedUrl: string}>(`${environment.apiUrl}/gcp/downloadSignUrl`, {
      params: {path: params.path, opportunity_id: params.supplier_id, relativePath: 'supplier/'},
    });
  }

  public deleteFile(params: { supplier_id: number; id: number }) {
    return this.http.delete(`${environment.apiUrl}/stores/deleteDocument`, {
      body: params,
    });
  }
}
