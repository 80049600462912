import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { EntityFormData, } from '../interfaces/client.interface';
import { TableList } from '../services/client.service';
export const EntityActions = createActionGroup({
  source: 'Client Component',
  events: {
    list: props<TableList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    entity: props<{ id: number }>(),
    entitySuccess: props<{ data: any }>(),
    entityFail: props<{ error: any }>(),
    update: props<Partial<EntityFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
    updateEntity: props<{ id: number; updates: Partial<EntityFormData> }>(),
    updateEntitySuccess: props<{ data: any }>(),
    updateEntityFail: props<{ error: any }>(),
    subscriptionEntity: props<{ id: number }>(),
    subscriptionEntitySuccess: props<{ data: any }>(),
    subscriptionEntityFail: props<{ error: any }>(),
  },
});

