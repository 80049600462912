import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Tb_Settings } from '../interfaces/settings.interface';

export interface SettingsList {
  limit: number;
  offset: number;
  search?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  public list(params: SettingsList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.http.get(`${environment.apiUrl}/unicorn/versions`, {
      params: httpParams,
    });
  }

  public createSettings(params: Partial<Tb_Settings>) {
    return this.http.post(`${environment.apiUrl}/unicorn/versions`, { ...params });
  }

  public updateSettings(params: Partial<Tb_Settings>) {
    return this.http.put(`${environment.apiUrl}/unicorn/versions`, { ...params });
  }

  public syncData(params: { id: number; value: string }) {
    return this.http.put(`${environment.apiUrl}/unicorn/sync`, { ...params });
  }
}
