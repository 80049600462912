import { createActionGroup, props } from '@ngrx/store';
import { BannerList } from '../services/slots.service';
import { BannerFormData } from '../interfaces/slots.interface';

export const BannersActions = createActionGroup({
  source: 'Slots Component',
  events: {
    list: props<BannerList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    slot: props<{ id: number }>(),
    bannerSuccess: props<{ data: any }>(),
    bannerFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<BannerFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<BannerFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});
