import { createReducer, on } from '@ngrx/store';

import { LpsActions } from './lps.actions';
import { LpsState } from '../interfaces/lps.interface';

const initialState: LpsState = {
  lps: null,
  loading: false,
  error: null,
  lp: null,
  lpLoading: false,
  lpError: null,
  deleteLoading: false,
  deleteLps: false,
  deleteError: null,
  createLoading: false,
  createLps: false,
  createError: null,
  updateLoading: false,
  updateLps: false,
  updateError: null,
};

export const lpsReducer = createReducer(
  initialState,
  on(LpsActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(LpsActions.listSuccess, (state, { data }) => ({
    ...state,
    lps: data,
    loading: true,
    error: null,
  })),
  on(LpsActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(LpsActions.lp, (state) => ({
    ...state,
    chain: null,
    chainLoading: false,
    chainError: null,
  })),
  on(LpsActions.lpSuccess, (state, { data }) => ({
    ...state,
    chain: data,
    chainLoading: true,
    chainError: null,
  })),
  on(LpsActions.lpFail, (state, { error }) => ({
    ...state,
    chain: null,
    chainLoading: false,
    chainError: error,
  })),
  on(LpsActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteLps: false,
  })),
  on(LpsActions.deleteSuccess, (state) => ({
    ...state,
    deleteLps: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(LpsActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteLps: false,
  })),
  on(LpsActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createLps: false,
  })),
  on(LpsActions.createSuccess, (state) => ({
    ...state,
    createLps: true,
    createLoading: true,
    createError: null,
  })),
  on(LpsActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createLps: false,
  })),
  on(LpsActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateLps: false,
  })),
  on(LpsActions.updateSuccess, (state) => ({
    ...state,
    updateLps: true,
    updateLoading: true,
    updateError: null,
  })),
  on(LpsActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateLps: false,
  })),
);

export const lpsFeatureKey = 'lps';
