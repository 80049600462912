import { createReducer, on } from '@ngrx/store';

import { GroupsActions } from './groups.actions';
import { GroupsState } from '../interfaces/groups.interface';

const initialState: GroupsState = {
  groups: null,
  loading: false,
  error: null,
  suppliers: null,
  suppliersLoading: false,
  suppliersError: null,
  group: null,
  relatedStores: [],
  groupLoading: false,
  groupError: null,
  deleteLoading: false,
  deleteGroups: false,
  deleteError: null,
  createLoading: false,
  createGroups: false,
  createError: null,
  updateLoading: false,
  updateGroups: false,
  updateError: null,
};

export const groupsReducer = createReducer(
  initialState,
  on(GroupsActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(GroupsActions.listSuccess, (state, { data }) => ({
    ...state,
    groups: data,
    loading: true,
    error: null,
  })),
  on(GroupsActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(GroupsActions.suppliers, (state) => ({
    ...state,
    suppliersLoading: false,
    suppliersError: null,
  })),
  on(GroupsActions.suppliersSuccess, (state, { data }) => ({
    ...state,
    suppliers: data,
    suppliersLoading: true,
    suppliersError: null,
  })),
  on(GroupsActions.suppliersFail, (state, { error }) => ({
    ...state,
    suppliersLoading: false,
    suppliersError: error,
  })),
  on(GroupsActions.group, (state) => ({
    ...state,
    group: null,
    groupLoading: false,
    groupError: null,
  })),
  on(GroupsActions.groupSuccess, (state, { data }) => ({
    ...state,
    group: data,
    groupLoading: true,
    groupError: null,
  })),
  on(GroupsActions.groupFail, (state, { error }) => ({
    ...state,
    group: null,
    groupLoading: false,
    groupError: error,
  })),
  on(GroupsActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteGroups: false,
  })),
  on(GroupsActions.deleteSuccess, (state) => ({
    ...state,
    deleteGroups: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(GroupsActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteGroups: false,
  })),
  on(GroupsActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createGroups: false,
  })),
  on(GroupsActions.createSuccess, (state) => ({
    ...state,
    createGroups: true,
    createLoading: true,
    createError: null,
  })),
  on(GroupsActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createGroups: false,
  })),
  on(GroupsActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateGroups: false,
  })),
  on(GroupsActions.updateSuccess, (state) => ({
    ...state,
    updateGroups: true,
    updateLoading: true,
    updateError: null,
  })),
  on(GroupsActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateGroups: false,
  })),
);

export const groupsFeatureKey = 'groups';
