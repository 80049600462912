import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OpportunityFormData } from '../interfaces/opportunity.interface';
import { catchError, forkJoin, of, switchMap, throwError } from 'rxjs';

export interface OpportunityList {
  limit: number;
  offset: number;
  search?: string;
  dealType?: number;
}

@Injectable({
  providedIn: 'root',
})
export class OpportunityService {
  constructor(private http: HttpClient) {}

  public list(params: OpportunityList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params, dealType: params.dealType ?? 6 },
    });

    return this.http.get(`${environment.apiUrl}/opportunity/`, {
      params: httpParams,
    });
  }

  public getExternalCodes( id: number) {
    return this.http.get<string[]>(`${environment.apiUrl}/opportunity/externalCode/${id}`);
  }

  public updateCdaStatus( id: number, status: number) {
    return this.http.put(`${environment.apiUrl}/opportunity/cda`, {
      id,
      status
    });
  }

  public duplicateCda( cdaOriginalId: number) {
    return this.http.post(`${environment.apiUrl}/opportunity/duplicate/cda`, {
      cdaOriginalId,
    });
  }

  public getOpportunity(params: { id: number, dealType?: number }) {
    return this.http.get(`${environment.apiUrl}/opportunity/${params.id}`, {
      params: {
        dealType: params.dealType ?? 6,
      },
    });
  }

  public categories(params: { limit: number; offset: number }) {
    return this.http.get(`${environment.apiUrl}/unicorn/categories`, {
      params,
    });
  }

  public suppliers(params: {
    limit: number;
    offset: number;
    name?: string;
    selectedMalls?: number[];
  }) {
    return this.http.get(`${environment.apiUrl}/unicorn/suppliers`, {
      params,
    });
  }

  public malls(params: { name?: string }) {
    return this.http.get(`${environment.apiUrl}/unicorn/malls`, {
      params,
    });
  }

  public delete(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/opportunity`, {
      body: { ids },
    });
  }

  public ingestExternalCodes(
    opportunityId: number,
    cda: number = 0,
    codes: string[],
  ) {
    console.log('ingestExternalCodes');
    return this.http.post(`${environment.apiUrl}/opportunity/externalCode`, {
      opportunityId,
      cda,
      codes,
    });
  }

  public updateOpportunityStatus(
    id: number,
  ) {
    console.log('ingestExternalCodes');
    return this.http.put(`${environment.apiUrl}/opportunity/status`, {
      id,
    });
  }

  public create(params: Partial<OpportunityFormData>) {
    return this.http.post(`${environment.apiUrl}/opportunity`, params);
  }

  public update(params: Partial<OpportunityFormData>) {
    return this.http.put(`${environment.apiUrl}/opportunity`, { ...params });
  }

  public duplicate(params: Partial<OpportunityFormData>) {
    return this.http.post(`${environment.apiUrl}/opportunity/duplicate`, params);
  }

  public tags() {
    return this.http.get(`${environment.apiUrl}/unicorn/tags`);
  }

  public opportunities(params: { limit: number; offset: number, dealType?: number, name?: string }) {
    return this.http.get(`${environment.apiUrl}/unicorn/opportunities`, {
      params,
    });
  }

  public getImagesOpportunityId(opportunityId: number) {
    return this.http.get(`${environment.apiUrl}/unicorn/opportunity/images`, {
      params: {
        opportunityId,
      },
    });
  }

  public getOpportunityCdaExternalCodeCount(opportunityId: number, dealId?: number) {
    return this.http.get<{ id: number, name: string, numberOfExternalCodes: number }[]>(`${environment.apiUrl}/opportunity/opportunityCda/externalCode`, {params: {opportunityId, ...(dealId && {dealId})}});
  }

  public importExternalCodes(opportunityId: number,  limit: number, dealId?: number, importDealId?:number) {
    return this.http.post(`${environment.apiUrl}/opportunity/opportunityCda/externalCode`, {
      opportunityId, 
      limit,
      ...(importDealId && {importDealId}),
      ...(dealId && {dealId})
    });
  }

  
  private getSignUrls(params: {
    filesType: { name: string; type: string }[];
    folder: string;
  }) {
    return this.http.post(`${environment.apiUrl}/gcp/signUrls`, params);
  }

  private upload(files: { signedUrl: string; file: File }[]) {
    const requests = files.map(({ signedUrl, file }) => {
      return this.http.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      }).pipe(
        catchError((error) => {
          // Log the error or handle it as needed
          console.error('Upload failed:', error);
          // You can rethrow the error or return a custom error message
          return throwError(() => new Error('File upload failed. Please try again.'));
        })
      );
      
    });
    return forkJoin(requests);
  }

  public uploadImage({
    files,
    galleryId
  }: {
    files: {
      type: string;
      file: File;
      name: string;
    }[];
    folder: string;
    galleryId: number
  }) {
    return this.getSignUrls({
      filesType: files.map((f) => ({ name: f.name, type: f.type })),
      folder: String(galleryId),
    }).pipe(
      switchMap((res: unknown) => {
        const signedUrls = (
          res as {
            signedUrls: {
              name: string;
              signedUrl: string;
              relativeLink: string;
            }[];
          }
        ).signedUrls;

        const signedFiles = signedUrls.map((t, index) => {
          const file = new File([files[index].file], t.name, {
            type: files[index].file.type,
          });
          return {
            signedUrl: t.signedUrl,
            file,
          };
        });
        return this.upload(signedFiles).pipe(
          switchMap(() => {
            return of(signedUrls.map((t) => t.relativeLink));
          }),
        );
      }),
    );
  }









  // private getSignUrlsGCP(params: {
  //   filesType: { name: string; type: string }[];
  //   folder: string;
  // }) {
  //   return this.http.post(`${environment.apiUrl}/gcp/signUrls`, params);
  // }

  // private uploadGCP(files: { signedUrl: string; file: File }[]) {
  //   const requests = files.map(({ signedUrl, file }) => {
  //     return this.http.put(signedUrl, file, {
  //       headers: {
  //         'Content-Type': file.type,
  //       },
  //     });
  //   });
  //   return forkJoin(requests);
  // }

  // public uploadImageGCP({
  //   files,
  //   galleryId,
  // }: {
  //   files: {
  //     type: string;
  //     file: File;
  //     name: string;
  //   }[];
  //   folder: string;
  //   galleryId: number;
  // }) {
  //   return this.getSignUrlsGCP({
  //     filesType: files.map((f) => ({ name: f.name+'.'+f.file.name.split('.').pop(), type: f.type })),
  //     folder: String(galleryId),
  //   }).pipe(
  //     switchMap((res: unknown) => {
  //       const signedUrls = (
  //         res as {
  //           signedUrls: {
  //             name: string;
  //             signedUrl: string;
  //             relativeLink: string;
  //           }[];
  //         }
  //       ).signedUrls;

  //       const signedFiles = signedUrls.map((t, index) => {
  //         const file = new File([files[index].file], t.name, {
  //           type: files[index].file.type,
  //         });
  //         return {
  //           signedUrl: t.signedUrl,
  //           file,
  //         };
  //       });
  //       return this.uploadGCP(signedFiles).pipe(
  //         switchMap(() => {
  //           return of(signedUrls.map((t) => t.relativeLink));
  //         }),
  //       );
  //     }),
  //   );
  // }

  // private getSignUrls(params: {
  //   filesType: { name: string; type: string }[];
  //   folder: string;
  // }) {
  //   return this.http.post(`${environment.apiUrl}/cloudinary/signUrls`, params);
  // }

  // private upload(
  //   signedFiles: {
  //     uploadUrl: string;
  //     file: File;
  //     api_key: string;
  //     timestamp: number;
  //     signature: string;
  //     relativeLink: string;
  //   }[],
  // ) {
  //   const uploadObservables = signedFiles.map((signedFile) => {
  //     const formData = new FormData();
  //     formData.append('file', signedFile.file);
  //     formData.append('api_key', signedFile.api_key);
  //     formData.append('timestamp', String(signedFile.timestamp));
  //     formData.append('signature', signedFile.signature);
  //     formData.append('public_id', signedFile.relativeLink);

  //     return this.http.post(signedFile.uploadUrl, formData);
  //   });

  //   return forkJoin(uploadObservables);
  // }

  // public getSignUrlObservable({
  //   files,
  //   folder,
  // }: {
  //   files: {
  //     type: string;
  //     file: File;
  //     name: string;
  //   }[];
  //   folder: string;
  // }) {
  //   return this.getSignUrlsGCP({
  //     filesType: files.map((f) => ({ name: f.name+'.'+f.file.name.split('.').pop(), type: f.type })),
  //     folder,
  //   }).pipe(
  //     switchMap((res: unknown) => {
  //       const signedUrls = (
  //         res as {
  //           signedUrls: {
  //             name: string;
  //             uploadUrl: string;
  //             relativeLink: string;
  //             api_key: string;
  //             signature: string;
  //             timestamp: number;
  //           }[];
  //         }
  //       ).signedUrls;

  //       const signedFiles = signedUrls.map((t, index) => {
  //         const file = new File([files[index].file], t.name, {
  //           type: files[index].file.type,
  //         });
  //         return {
  //           ...t,
  //           file,
  //         };
  //       });
  //       return this.upload(signedFiles).pipe(
  //         switchMap(() => {
  //           return of(signedUrls.map((t) => t.relativeLink));
  //         }),
  //       );
  //     }),
  //   );
  // }

  // public uploadImage({
  //   files,
  //   folder,
  //   galleryId,
  // }: {
  //   files: {
  //     type: string;
  //     file: File;
  //     name: string;
  //   }[];
  //   folder: string;
  //   galleryId: number;
  // }) {
  //   return this.uploadImageGCP({ files, folder, galleryId }).pipe(
  //     catchError((err) => {
  //       console.error('Error in uploadImageGCP:', err);
  //       // Return an empty observable to continue the process
  //       return of(null);
  //     }),
  //     switchMap(() => {
  //       return this.getSignUrlObservable({ files, folder });
  //     }),
  //   );
  // }
}
