import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CouponFormData } from '../interfaces/parking.interface';

export interface CouponList {
  limit: number;
  offset: number;
  search?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(private http: HttpClient) {}

  public list(params: CouponList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.http.get(`${environment.apiUrl}/coupons/`, {
      params: httpParams,
    });
  }

  public getCoupon(params: { id: number }) {
    return this.http.get(`${environment.apiUrl}/coupons/${params.id}`);
  }

  public deleteCoupon(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/coupons/`, {
      body: { ids },
    });
  }

  public createParkingManager(params: Partial<CouponFormData>) {
    return this.http.post(`${environment.apiUrl}/parking-manager/`, params);
  }

  public updateParkingManager(params: Partial<CouponFormData>) {
    return this.http.put(`${environment.apiUrl}/parking-manager/`, { ...params });
  }
}
