import { Routes } from '@angular/router';
import { LayoutComponent } from './layouts/layout/layout.component';
import {
  AuthGuard,
  LoginGuard,
  RoleGuard,
} from './shared/core/auth/auth.guard';
import { Error404Component } from './pages/pages/errors/error-404/error-404.component';

export const routes: Routes = [
  {
    path: 'login',
    canActivate: [],
    loadComponent: () =>
      import('./pages/pages/auth/login/login.component').then(
        (m) => m.LoginComponent,
      ),
  },
  {
    path: 'register',
    canActivate: [LoginGuard],
    loadComponent: () =>
      import('./pages/pages/auth/register/register.component').then(
        (m) => m.RegisterComponent,
      ),
  },
  {
    path: 'forms/bug',
    loadComponent: () =>
      import('./pages/pages/forms/bugs/bugs.component').then(
        (m) => m.ComingSoonComponent,
      ),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboards/myofer',

        loadComponent: () =>
          import(
            './pages/dashboards/dashboard-analytics/dashboard-analytics.component'
          ).then((m) => m.DashboardAnalyticsComponent),
      },
      {
        path: 'cms/malls',

        loadComponent: () =>
          import(
            './pages/apps/cms/malls/malls.component'
          ).then((m) => m.DashboardMallComponent),
      },
      {
        path: 'cms/categories',
        loadComponent: () =>
          import('./pages/apps/cms/categories/category.component').then(
            (m) => m.DashboardCategoryComponent,
          ),
      },
      {
        path: 'loyalty/receipts',
        loadComponent: () =>
          import('./pages/apps/loyalty/receipts/receipts.component').then(
            (m) => m.LoyaltyReceiptsComponent,
          ),
      },
      {
        path: 'cms/opportunity',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/apps/opportunity/opportunity.component').then(
                (m) => m.DashboardOpportunityComponent,
              ),
          },
          {
            path: 'add',
            loadComponent: () =>
              import(
                './pages/apps/opportunity/components/createUpdate/create-update.component'
              ).then((m) => m.OpportunityCreateUpdateComponent),
          },
          {
            path: 'edit/:id',
            loadComponent: () =>
              import(
                './pages/apps/opportunity/components/createUpdate/create-update.component'
              ).then((m) => m.OpportunityCreateUpdateComponent),
          },
        ],
      },
      {
        path: 'cms/flashDeals',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/apps/flashDeals/opportunity.component').then(
                (m) => m.DashboardOpportunityComponent,
              ),
          },
          {
            path: 'add',
            loadComponent: () =>
              import(
                './pages/apps/flashDeals/components/createUpdate/create-update.component'
              ).then((m) => m.OpportunityCreateUpdateComponent),
          },
          {
            path: 'edit/:id',
            loadComponent: () =>
              import(
                './pages/apps/flashDeals/components/createUpdate/create-update.component'
              ).then((m) => m.OpportunityCreateUpdateComponent),
          },
        ],
      },
      {
        path: 'cms/saleDeals',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/apps/saleDeals/opportunity.component').then(
                (m) => m.DashboardOpportunityComponent,
              ),
          },
          {
            path: 'add',
            loadComponent: () =>
              import(
                './pages/apps/saleDeals/components/createUpdate/create-update.component'
              ).then((m) => m.OpportunityCreateUpdateComponent),
          },
          {
            path: 'edit/:id',
            loadComponent: () =>
              import(
                './pages/apps/saleDeals/components/createUpdate/create-update.component'
              ).then((m) => m.OpportunityCreateUpdateComponent),
          },
        ],
      },
      {
        path: 'parking/list',
        canActivate: [RoleGuard],
        data: { roles: ['Admin'] },
        loadComponent: () =>
          import(
            './pages/apps/parking/parking.component'
          ).then((m) => m.ParkingComponent),
      },
      {
        path: 'gift-card/list',
        canActivate: [RoleGuard],
        data: { roles: ['Admin'] },
        loadComponent: () =>
          import(
            './pages/apps/giftCard/giftCard.component'
          ).then((m) => m.GiftCardComponent),
      },
      {
        path: 'parking-manager/dashboard',
        canActivate: [RoleGuard],
        data: { roles: ['Admin'] },
        loadComponent: () =>
          import(
            './pages/apps/parkingManager/parking.component'
          ).then((m) => m.ParkingManagerComponent),
      },
      {
        path: 'admin/users',
        canActivate: [RoleGuard],
        data: { roles: ['Admin'] },
        loadComponent: () =>
          import(
            './pages/apps/admin/users/users.component'
          ).then((m) => m.UsersComponent),

      },
      {
        path: 'admin/settings',
        canActivate: [RoleGuard],
        data: { roles: ['Admin'] },
        loadComponent: () =>
          import(
            './pages/apps/admin/settings/settings.component'
          ).then((m) => m.SettingsComponent),

      },
      {
        path: 'cms/groups',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        loadComponent: () =>
          import(
            './pages/apps/cms/groups/groups.component'
          ).then((m) => m.DashboardGroupsComponent),
      },

      {
        path: 'cms/chains',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        loadComponent: () =>
          import(
            './pages/apps/cms/chains/chains.component'
          ).then((m) => m.DashboardChainsComponent),
      },
      {
        path: 'cms/chains/:id',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        loadComponent: () =>
          import(
            './pages/apps/cms/chains/chains.component'
          ).then((m) => m.DashboardChainsComponent),
      },
      {
        path: 'cms/content-pages',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        loadComponent: () =>
          import(
            './pages/apps/cms/content-pages/content-pages.component'
          ).then((m) => m.ContentPagesComponent),
      },
      {
        path: 'cms/lps',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        loadComponent: () =>
          import(
            './pages/apps/cms/lps/lps.component'
          ).then((m) => m.LpsComponent),
      },
      {
        path: 'cms/slots',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        loadComponent: () =>
          import(
            './pages/apps/cms/slots/slots.component'
          ).then((m) => m.BannersComponent),
      },
      {
        path: 'cms/documentation',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        loadComponent: () =>
          import(
            './pages/documentation/documentation.component'
          ).then((m) => m.DocumentationComponent),
      },
      {
        path: 'cms/coupons',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        loadComponent: () =>
          import(
            './pages/apps/coupons/coupons.component'
          ).then((m) => m.CouponComponent),
      },
      {
        path: 'cms/stores',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/apps/cms/stores/stores.component'
              ).then((m) => m.DashboardStoresComponent),
          },
          {
            path: 'add',
            loadComponent: () =>
              import(
                './pages/apps/cms/stores/components/storesCreateUpdate/stores-create-update.component'
              ).then((m) => m.StoresCreateUpdateComponent),
          },
          {
            path: 'edit/:id',
            loadComponent: () =>
              import(
                './pages/apps/cms/stores/components/storesCreateUpdate/stores-create-update.component'
              ).then((m) => m.StoresCreateUpdateComponent),
          },
        ],
      },
      {
        path: 'cms/events',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/apps/cms/events/events.component'
              ).then((m) => m.DashboardEventsComponent),
          },
          {
            path: 'add',
            loadComponent: () =>
              import(
                './pages/apps/cms/events/components/eventsCreateUpdate/events-create-update.component'
              ).then((m) => m.EventsCreateUpdateComponent),
          },
          {
            path: 'edit/:id',
            loadComponent: () =>
              import(
                './pages/apps/cms/events/components/eventsCreateUpdate/events-create-update.component'
              ).then((m) => m.EventsCreateUpdateComponent),
          },
        ],
      },
      {
        path: 'cms/banners',
        canActivate: [RoleGuard],
        data: { roles: ['Admin', 'Manager', 'User'] },
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/apps/banners/banners.component'
              ).then((m) => m.BannersComponent),
          },
          {
            path: 'add',
            loadComponent: () =>
              import(
                './pages/apps/cms/stores/components/storesCreateUpdate/stores-create-update.component'
              ).then((m) => m.StoresCreateUpdateComponent),
          },
          {
            path: 'edit/:id',
            loadComponent: () =>
              import(
                './pages/apps/cms/stores/components/storesCreateUpdate/stores-create-update.component'
              ).then((m) => m.StoresCreateUpdateComponent),
          },
        ],
      },
      {
        path: 'crm/clients',
        loadComponent: () =>
          import('./pages/apps/crm/clients/clients.component').then(
            (m) => m.ClientsComponent,
          ),
      },
      {
        path: 'crm/clients/:id',
        loadChildren: () => import('./pages/apps/crm/client/clients.routes'),
      },
      {
        path: 'crm/orders',
        loadComponent: () =>
          import('./pages/apps/crm/orders/orders.component').then(
            (m) => m.OrdersComponent,
          ),
      },
      {
        path: '',
        redirectTo: 'dashboards/myofer',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: Error404Component,
      },
    ],
  },
];
