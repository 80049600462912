import { createReducer, on } from '@ngrx/store';

import { StoresActions } from './stores.actions';
import { StoresState } from '../interfaces/stores.interface';

const initialState: StoresState = {
  stores: null,
  loading: false,
  error: null,
  suppliers: null,
  status: [],
  relatedStores: [],
  isChain: false,
  suppliersLoading: false,
  suppliersError: null,
  store: null,
  storeLoading: false,
  storeError: null,
  deleteLoading: false,
  deleteStores: false,
  deleteError: null,
  createLoading: false,
  createStores: null,
  createError: null,
  updateLoading: false,
  updateStores: null,
  updateError: null,
  image: null,
  imageLoading: false,
  imageError: null,
};

export const storesReducer = createReducer(
  initialState,
  on(StoresActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(StoresActions.listSuccess, (state, { data }) => ({
    ...state,
    stores: data,
    loading: true,
    error: null,
  })),
  on(StoresActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(StoresActions.listNew, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(StoresActions.listNewSuccess, (state, { data }) => ({
    ...state,
    stores: data,
    loading: true,
    error: null,
  })),
  on(StoresActions.listNewFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(StoresActions.suppliers, (state) => ({
    ...state,
    suppliersLoading: false,
    suppliersError: null,
  })),
  on(StoresActions.suppliersSuccess, (state, { data }) => ({
    ...state,
    suppliers: data,
    suppliersLoading: true,
    suppliersError: null,
  })),
  on(StoresActions.suppliersFail, (state, { error }) => ({
    ...state,
    suppliersLoading: false,
    suppliersError: error,
  })),
  on(StoresActions.store, (state) => ({
    ...state,
    store: null,
    storeLoading: false,
    storeError: null,
  })),
  on(StoresActions.storeSuccess, (state, { data }) => ({
    ...state,
    store: data,
    storeLoading: true,
    storeError: null,
  })),
  on(StoresActions.storeFail, (state, { error }) => ({
    ...state,
    store: null,
    storeLoading: false,
    storeError: error,
  })),
  on(StoresActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteStores: false,
  })),
  on(StoresActions.deleteSuccess, (state) => ({
    ...state,
    deleteStores: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(StoresActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteStores: false,
  })),
  on(StoresActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createStores: null,
  })),
  on(StoresActions.createSuccess, (state, { data }) => ({
    ...state,
    createStores: data,
    createLoading: true,
    createError: null,
  })),
  on(StoresActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createStores: null,
  })),
  on(StoresActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateStores: null,
  })),
  on(StoresActions.updateSuccess, (state, { data }) => ({
    ...state,
    updateStores: data,
    updateLoading: true,
    updateError: null,
  })),
  on(StoresActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateStores: null,
  })),
  on(StoresActions.image, (state) => ({
    ...state,
    image: null,
    imageLoading: false,
    imageError: null,
  })),
  on(StoresActions.imageSuccess, (state, { data }) => ({
    ...state,
    image: data,
    imageLoading: true,
    imageError: null,
  })),
  on(StoresActions.imageFail, (state, { error }) => ({
    ...state,
    image: null,
    imageLoading: false,
    imageError: error,
  })),
);

export const storesFeatureKey = 'stores';
