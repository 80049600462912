import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { ReceiptList } from '../services/receipts.service';
import { ReceiptFormData, Tb_Receipt } from '../interfaces/receipts.interface';

export const ReceiptsActions = createActionGroup({
  source: 'Receipt Component',
  events: {
    list: props<ReceiptList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    receipt: props<{ id: number }>(),
    receiptSuccess: props<{ data: any }>(),
    receiptFail: props<{ error: any }>(),
    cancelReceipt: props<{ id: string }>(),
    cancelSuccess: props<{ data: any }>(),
    cancelFail: props<{ error: any }>(),
    createReceipt: props<Partial<ReceiptFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    updateReceipt: props<Partial<Tb_Receipt>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});
