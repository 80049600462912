import { createReducer, on } from '@ngrx/store';
import { ReceiptsActions } from './receipts.actions';
import { ReceiptState } from '../interfaces/receipts.interface';

const initialState: ReceiptState = {
  receipts: null,
  loading: false,
  error: null,
  receipt: null,
  receiptLoading: false,
  receiptError: null,
  deleteLoading: false,
  deleteReceipt: false,
  deleteError: null,
  createLoading: false,
  createReceipt: false,
  createError: null,
  createSuccess: false,
  updateLoading: false,
  updateReceipt: false,
  updateError: null,
  updateSuccess: false,
};

export const receiptReducer = createReducer(
  initialState,

  // 🔹 Fix: Set `loading: true` when fetching the receipt list
  on(ReceiptsActions.list, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // 🔹 Fix: Set `loading: false` when list fetch is successful
  on(ReceiptsActions.listSuccess, (state, { data }) => ({
    ...state,
    receipts: data,
    loading: false, // Set loading to false
    error: null,
  })),

  on(ReceiptsActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(ReceiptsActions.receipt, (state) => ({
    ...state,
    receiptLoading: true, // Fix: Set loading to true while fetching
    receipt: null,
    receiptError: null,
  })),

  on(ReceiptsActions.receiptSuccess, (state, { data }) => ({
    ...state,
    receipt: data,
    receiptLoading: false, // Set loading to false
    receiptError: null,
  })),

  on(ReceiptsActions.receiptFail, (state, { error }) => ({
    ...state,
    receipt: null,
    receiptLoading: false,
    receiptError: error,
  })),

  on(ReceiptsActions.cancelReceipt, (state) => ({
    ...state,
    deleteLoading: true, // Fix: Set deleteLoading to true
    deleteError: null,
    deleteReceipt: false,
  })),

  on(ReceiptsActions.cancelSuccess, (state) => ({
    ...state,
    deleteReceipt: true,
    deleteLoading: false, // Fix: Set deleteLoading to false
    deleteError: null,
  })),

  on(ReceiptsActions.cancelFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteReceipt: false,
  })),

  on(ReceiptsActions.createReceipt, (state) => ({
    ...state,
    createLoading: true, // Fix: Set createLoading to true
    createError: null,
    createReceipt: false,
  })),

  on(ReceiptsActions.createSuccess, (state) => ({
    ...state,
    createReceipt: true,
    createLoading: false, // Fix: Set createLoading to false
    createError: null,
  })),

  on(ReceiptsActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createReceipt: false,
  })),

  on(ReceiptsActions.updateReceipt, (state) => ({
    ...state,
    updateLoading: true, // Fix: Set updateLoading to true
    updateError: null,
    updateReceipt: false,
  })),

  on(ReceiptsActions.updateSuccess, (state) => ({
    ...state,
    updateReceipt: true,
    updateLoading: false, // Fix: Set updateLoading to false
    updateError: null,
  })),

  on(ReceiptsActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateReceipt: false,
  }))
);

// 🔹 Ensure this key matches StoreModule.forFeature('receipts', receiptReducer)
export const receiptKey = 'receipts';
