import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ReceiptsActions } from './receipts.actions';

import { map, mergeMap } from 'rxjs/operators';
import { ReceiptList, ReceiptsService } from '../services/receipts.service';
import { Tb_Receipt } from '../interfaces/receipts.interface';

@Injectable()
export class ReceiptEffects {
  constructor(
    private actions$: Actions,
    private receiptService: ReceiptsService,
  ) {}

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiptsActions.list),
      map(
        ({ type: _type, ...params }: ReceiptList & { type: string }) => params,
      ),
      mergeMap((params) => {
        return this.receiptService
          .list(params)
          .pipe(map((data) => ReceiptsActions.listSuccess({ data })));
      }),
    ),
  );

  getReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiptsActions.receipt),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.receiptService
          .getReceipt(params)
          .pipe(map((data) => ReceiptsActions.receiptSuccess({ data })));
      }),
    ),
  );

  updateReceipt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReceiptsActions.updateReceipt),
      map(({ type: _type, ...params }) => params as Partial<Tb_Receipt>),
      mergeMap((params) => {
        return this.receiptService
          .updateReceipt(params)
          .pipe(map((data) => ReceiptsActions.updateSuccess({ data })));
      }),
    ),
  );
}
