import { createReducer, on } from '@ngrx/store';
import { SettingsActions } from './settings.actions';
import { SettingsState } from '../interfaces/settings.interface';

const initialState: SettingsState = {
  settings: null,
  loading: false,
  error: null,
  setting: null,
  settingsLoading: false,
  settingsError: null,
  deleteLoading: false,
  deleteSettings: false,
  deleteError: null,
  createLoading: false,
  createSettings: false,
  createError: null,
  createSuccess: false,
  updateLoading: false,
  updateSettings: false,
  updateError: null,
  updateSuccess: false,
  syncLoading: false,
  syncError: null,
  syncSuccess: false,
};

export const settingsReducer = createReducer(
  initialState,

  on(SettingsActions.list, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),

  // 🔹 Fix: Set `loading: false` when list fetch is successful
  on(SettingsActions.listSuccess, (state, { data }) => ({
    ...state,
    settings: data,
    loading: false, // Set loading to false
    error: null,
  })),

  on(SettingsActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(SettingsActions.settings, (state) => ({
    ...state,
    settingsLoading: true, // Fix: Set loading to true while fetching
    settings: null,
    settingsError: null,
  })),

  on(SettingsActions.settingsSuccess, (state, { data }) => ({
    ...state,
    settings: data,
    settingsLoading: false, // Set loading to false
    settingsError: null,
  })),

  on(SettingsActions.settingsFail, (state, { error }) => ({
    ...state,
    settings: null,
    settingsLoading: false,
    settingsError: error,
  })),

  on(SettingsActions.cancelSettings, (state) => ({
    ...state,
    deleteLoading: true, // Fix: Set deleteLoading to true
    deleteError: null,
    deleteSettings: false,
  })),

  on(SettingsActions.cancelSuccess, (state) => ({
    ...state,
    deleteSettings: true,
    deleteLoading: false, // Fix: Set deleteLoading to false
    deleteError: null,
  })),

  on(SettingsActions.cancelFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteSettings: false,
  })),

  on(SettingsActions.createSettings, (state) => ({
    ...state,
    createLoading: true, // Fix: Set createLoading to true
    createError: null,
    createSettings: false,
  })),

  on(SettingsActions.createSuccess, (state) => ({
    ...state,
    createSettings: true,
    createLoading: false, // Fix: Set createLoading to false
    createError: null,
  })),

  on(SettingsActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createSettings: false,
  })),

  on(SettingsActions.updateSettings, (state) => ({
    ...state,
    updateLoading: true, // Fix: Set updateLoading to true
    updateError: null,
    updateSettings: false,
  })),

  on(SettingsActions.updateSuccess, (state) => ({
    ...state,
    updateSettings: true,
    updateLoading: false, // Fix: Set updateLoading to false
    updateError: null,
  })),

  on(SettingsActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateSettings: false,
  })),

  on(SettingsActions.syncData, (state) => ({
    ...state,
    settingsLoading: true, // Fix: Set loading to true while fetching
    settings: null,
    settingsError: null,
  })),

  on(SettingsActions.syncSuccess, (state, { data }) => ({
    ...state,
    settings: data,
    settingsLoading: false, // Fix: Set loading to false
    settingsError: null,
  })),

  on(SettingsActions.syncFail, (state, { error }) => ({
    ...state,
    settings: null,
    settingsLoading: false,
    settingsError: error,
  })),
);

export const settingsKey = 'settings';
