import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, timeout } from 'rxjs/operators';
import { startLoading, stopLoading } from './loading.actions';
import { AppState } from '../../../app.state';
import { NotificationService } from '../../core/notifications/notification.service'; // Update the path as needed

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    private notificationService: NotificationService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.store.dispatch(startLoading());

    return next.handle(req).pipe(
      timeout(30000),
      catchError((error: HttpErrorResponse) => {
        const errorMessage =
          error.error?.message || error.message || 'An unknown error occurred';
        if (!req.url.includes('api/user/refresh')) {
          this.notificationService.showError(
            'An error occurred: ' + errorMessage,
          );
        }
        return throwError(() => new Error(errorMessage));
      }),
      finalize(() => {
        this.store.dispatch(stopLoading());
      }),
    );
  }
}
