import { createActionGroup, props } from '@ngrx/store';
import { BannerList } from '../services/banners.service';
import { BannerFormData } from '../interfaces/banners.interface';

export const BannersActions = createActionGroup({
  source: 'Banners Component',
  events: {
    list: props<BannerList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    banner: props<{ id: number }>(),
    bannerSuccess: props<{ data: any }>(),
    bannerFail: props<{ error: any }>(),
    delete: props<{ id: number }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<BannerFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<BannerFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
    slots: props<{ limit: number; offset: number }>(),
    slotsSuccess: props<{ data: any }>(),
    slotsFail: props<{ error: any }>(),
    malls: props<{ name?: string }>(),
    mallsSuccess: props<{ data: any }>(),
    mallsFail: props<{ error: any }>(),
  },
});
