import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { ContentPageList } from '../services/content-pages.service';
import { ContentPageFormData, Tb_ContentPage } from '../interfaces/content-pages.interface';

export const ContentPagesActions = createActionGroup({
  source: 'ContentPage Component',
  events: {
    list: props<ContentPageList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    contentPage: props<{ id: number }>(),
    contentPageSuccess: props<{ data: any }>(),
    contentPageFail: props<{ error: any }>(),
    cancelContentPage: props<{ id: string }>(),
    cancelSuccess: props<{ data: any }>(),
    cancelFail: props<{ error: any }>(),
    createContentPage: props<Partial<ContentPageFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    updateContentPage: props<Partial<Tb_ContentPage>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});
