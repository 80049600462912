import { createReducer, on } from '@ngrx/store';

import { BannersActions } from './slots.actions';
import { BannersState } from '../interfaces/slots.interface';

const initialState: BannersState = {
  slots: null,
  loading: false,
  error: null,
  slot: null,
  bannerLoading: false,
  bannerError: null,
  deleteLoading: false,
  deleteBanners: false,
  deleteError: null,
  createLoading: false,
  createBanners: false,
  createError: null,
  updateLoading: false,
  updateBanners: false,
  updateError: null,
};

export const bannersReducer = createReducer(
  initialState,
  on(BannersActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(BannersActions.listSuccess, (state, { data }) => ({
    ...state,
    slots: data,
    loading: true,
    error: null,
  })),
  on(BannersActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(BannersActions.slot, (state) => ({
    ...state,
    chain: null,
    chainLoading: false,
    chainError: null,
  })),
  on(BannersActions.bannerSuccess, (state, { data }) => ({
    ...state,
    chain: data,
    chainLoading: true,
    chainError: null,
  })),
  on(BannersActions.bannerFail, (state, { error }) => ({
    ...state,
    chain: null,
    chainLoading: false,
    chainError: error,
  })),
  on(BannersActions.delete, (state) => ({
    ...state,
    deleteLoading: false,
    deleteError: null,
    deleteBanners: false,
  })),
  on(BannersActions.deleteSuccess, (state) => ({
    ...state,
    deleteBanners: true,
    deleteLoading: true,
    deleteError: null,
  })),
  on(BannersActions.deleteFail, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
    deleteBanners: false,
  })),
  on(BannersActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createBanners: false,
  })),
  on(BannersActions.createSuccess, (state) => ({
    ...state,
    createBanners: true,
    createLoading: true,
    createError: null,
  })),
  on(BannersActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createBanners: false,
  })),
  on(BannersActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateBanners: false,
  })),
  on(BannersActions.updateSuccess, (state) => ({
    ...state,
    updateBanners: true,
    updateLoading: true,
    updateError: null,
  })),
  on(BannersActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateBanners: false,
  })),
);

export const bannersFeatureKey = 'slots';
