import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { GroupList } from '../services/groups.service';
import { GroupFormData } from '../interfaces/groups.interface';

export const GroupsActions = createActionGroup({
  source: 'Groups Component',
  events: {
    list: props<GroupList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    group: props<{ id: number }>(),
    groupSuccess: props<{ data: any }>(),
    groupFail: props<{ error: any }>(),
    suppliers: props<{ offset: number; limit: number; name?: string; relatedId?: number }>(),
    suppliersSuccess: props<{ data: any }>(),
    suppliersFail: props<{ error: any }>(),
    delete: props<{ ids: number[] }>(),
    deleteSuccess: props<{ data: any }>(),
    deleteFail: props<{ error: any }>(),
    create: props<Partial<GroupFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<GroupFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
  },
});
