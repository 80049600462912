import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventFormData } from '../interfaces/events.interface';
import { forkJoin, of, switchMap } from 'rxjs';
import { Tb_Malls } from '../../../opportunity/interfaces/opportunity.interface';

export interface EventsList {
  offset: number;
  limit: number;
  title?: string;
  id?: string;
  supplier?: number;
  active?: number
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private http: HttpClient) {}

  public list(params: EventsList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/events/`, {
      params: httpParams,
    });
  }

  public getEvent(params: { id: number | undefined }) {
    return this.http.get(
      `${environment.apiUrl}/events/getEventById/${params.id}`,
    );
  }

  public create(params: Partial<EventFormData>) {
    return this.http.post(`${environment.apiUrl}/events/`, params);
  }

  public update(params: Partial<EventFormData>) {
    return this.http.put(`${environment.apiUrl}/events/`, { ...params });
  }

  public malls(
  ) {
    return this.http.get<Tb_Malls>(
      `${environment.apiUrl}/unicorn/malls`
    );
  }

  private getSignUrls(params: {
    filesType: { index: number; type: string }[];
    folder: string;
  }) {
    return this.http.post(`${environment.apiUrl}/cloudinary/signUrls`, {
      ...params,
      onlyFolder: true,
    });
  }

  private upload(
    signedFiles: {
      uploadUrl: string;
      file: File;
      api_key: string;
      timestamp: number;
      signature: string;
      relativeLink: string;
    }[],
  ) {
    const uploadObservables = signedFiles.map((signedFile) => {
      const formData = new FormData();
      formData.append('file', signedFile.file);
      formData.append('api_key', signedFile.api_key);
      formData.append('timestamp', String(signedFile.timestamp));
      formData.append('signature', signedFile.signature);
      formData.append('public_id', signedFile.relativeLink);

      return this.http.post(signedFile.uploadUrl, formData);
    });

    return forkJoin(uploadObservables);
  }

  public uploadImage({
    files,
    folder,
  }: {
    files: {
      name: string;
      fileType: string;
      file: File;
      index: number;
    }[];
    folder: string;
  }) {
    return this.getSignUrls({
      filesType: files.map((f) => ({
        index: f.index,
        type: f.fileType,
        name: f.name,
      })),
      folder,
    }).pipe(
      switchMap((res: unknown) => {
        const signedUrls = (
          res as {
            signedUrls: {
              name: string;
              uploadUrl: string;
              relativeLink: string;
              api_key: string;
              signature: string;
              timestamp: number;
            }[];
          }
        ).signedUrls;

        const signedFiles = signedUrls.map((t, index) => {
          const file = new File([files[index].file], t.name, {
            type: files[index].file.type,
          });
          return {
            ...t,
            file,
          };
        });
        return this.upload(signedFiles).pipe(
          switchMap(() => {
            return of(signedUrls.map((t) => t.relativeLink));
          }),
        );
      }),
    );
  }
}
