import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { BannersActions } from './slots.actions';

import { map, mergeMap } from 'rxjs/operators';
import { BannerList, BannersService } from '../services/slots.service';
import { BannerFormData } from '../interfaces/slots.interface';

@Injectable()
export class BannersEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.list),
      map(({ type: _type, ...params }: BannerList & { type: string }) => params),
      mergeMap((params) => {
        return this.BannersService
          .list(params)
          .pipe(map((data) => BannersActions.listSuccess({ data })));
      }),
    ),
  );

  getBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.slot),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.BannersService
          .getBanner(params)
          .pipe(map((data) => BannersActions.bannerSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.delete),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.BannersService
          .delete(param.ids)
          .pipe(map((data) => BannersActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<BannerFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.BannersService
          .create(params as Partial<BannerFormData>)
          .pipe(map((data) => BannersActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannersActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<BannerFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.BannersService
          .update(params as Partial<BannerFormData>)
          .pipe(map((data) => BannersActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private BannersService: BannersService,
  ) {}
}
