import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BannerFormData } from '../interfaces/banners.interface';

export interface BannerList {
  title?: string;
  slotId?: number;
  isActive?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BannersService {
  constructor(private http: HttpClient) {}

  public list(params: BannerList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/banners`, {
      params: httpParams,
    });
  }

  public getBanner(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/banners/${params.id}`,
    );
  }

  public delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/banners/`, { body: { id } });
  }

  public create(params: Partial<BannerFormData>) {
    const formData = new FormData();

    formData.append('title', params.title || '');
    formData.append('subTitle', params.subTitle || '');
    formData.append('link', params.link || '');
    formData.append('newWindow', String(params.newWindow));
    formData.append('isActiveLink', String(params.isActiveLink));
    formData.append('isActive', String(params.isActive));
    formData.append('slotId', String(params.slotId));
    formData.append('mallId', String(params.mallId));

    if (params.image === undefined || typeof params.image === 'string') { 

    } else {
      formData.append('image', params.image.file, params.image.file.name);
    }

    return this.http.post(`${environment.apiUrl}/banners/`, formData);
  }

  public update(params: Partial<BannerFormData>) {
    return this.http.put(`${environment.apiUrl}/banners/`, { ...params });
  }

  public slots(params: { limit: number; offset: number }) {
    return this.http.get(`${environment.apiUrl}/unicorn/slots`, {
      params,
    });
  }

  public malls(params: any) {
    return this.http.get(`${environment.apiUrl}/unicorn/malls`, {
      params,
    });
  }
}
