import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface GiftCardList {
  limit: number;
  offset: number;
  search?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GiftCardService {
  constructor(private http: HttpClient) {}

  public list(params: GiftCardList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.http.get(`${environment.apiUrl}/cal/list`, {
      params: httpParams,
    });
  }

  public getGiftCard(params: { id: number }) {
    return this.http.get(`${environment.apiUrl}/coupons/${params.id}`);
  }

  public uploadB2B(params: { formData: FormData }) {
    return this.http.post(`${environment.apiUrl}/cal/b2b`, params.formData);
  }

  public uploadPrep(params: { formData: FormData }) {
    return this.http.post(`${environment.apiUrl}/cal/prep`, params.formData, { responseType: 'blob' });
}

  public cancelGiftCard(id: string) {
    return this.http.post(`${environment.apiUrl}/cal/cancelGiftCard`, {
      userId: id,
    });
  }
}
