import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LpFormData } from '../interfaces/lps.interface';

export interface LpList {
  offset: number;
  limit: number;
  name?: string;
  id?: string;
  supplier?: number;
}

@Injectable({
  providedIn: 'root',
})
export class LpsService {
  constructor(private http: HttpClient) {}

  public list(params: LpList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/lps`, {
      params: httpParams,
    });
  }

  public getLp(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/lps/${params.id}`,
    );
  }

  public delete(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/lps/`, { body: { ids } });
  }

  public create(params: Partial<LpFormData>) {
    return this.http.post(`${environment.apiUrl}/lps/`, params);
  }

  public update(params: Partial<LpFormData>) {
    return this.http.put(`${environment.apiUrl}/lps/`, { ...params });
  }
}
