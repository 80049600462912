import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Tb_Receipt } from '../interfaces/receipts.interface';

export interface ReceiptList {
  limit: number;
  offset: number;
  search?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReceiptsService {
  constructor(private http: HttpClient) {}

  public list(params: ReceiptList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });
    return this.http.get(`${environment.apiUrl}/receipts`, {
      params: httpParams,
    });
  }

  public getReceipt(params: { id: number }) {
    return this.http.get(`${environment.apiUrl}/receipts/${params.id}`);
  }

  public updateReceipt(params: Partial<Tb_Receipt>) {
    return this.http.put(`${environment.apiUrl}/receipts/`, { ...params });
  }
}
