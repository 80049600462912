import { createReducer, on } from '@ngrx/store';

import { EventsActions } from './events.actions';
import { EventsState } from '../interfaces/events.interface';

const initialState: EventsState = {
  events: null,
  loading: false,
  error: null,
  suppliers: null,
  status: [],
  relatedEvents: [],
  event: null,
  eventLoading: false,
  eventError: null,
  createLoading: false,
  createEvents: null,
  createError: null,
  updateLoading: false,
  updateEvents: null,
  updateError: null,
  image: null,
  imageLoading: false,
  imageError: null,
};

export const eventsReducer = createReducer(
  initialState,
  on(EventsActions.list, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(EventsActions.listSuccess, (state, { data }) => ({
    ...state,
    events: data,
    loading: true,
    error: null,
  })),
  on(EventsActions.listFail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(EventsActions.event, (state) => ({
    ...state,
    event: null,
    eventLoading: false,
    eventError: null,
  })),
  on(EventsActions.eventSuccess, (state, { data }) => ({
    ...state,
    event: data,
    eventLoading: true,
    eventError: null,
  })),
  on(EventsActions.eventFail, (state, { error }) => ({
    ...state,
    event: null,
    eventLoading: false,
    eventError: error,
  })),
  on(EventsActions.create, (state) => ({
    ...state,
    createLoading: false,
    createError: null,
    createEvents: null,
  })),
  on(EventsActions.createSuccess, (state, { data }) => ({
    ...state,
    createEvents: data,
    createLoading: true,
    createError: null,
  })),
  on(EventsActions.createFail, (state, { error }) => ({
    ...state,
    createLoading: false,
    createError: error,
    createEvents: null,
  })),
  on(EventsActions.update, (state) => ({
    ...state,
    updateLoading: false,
    updateError: null,
    updateEvents: null,
  })),
  on(EventsActions.updateSuccess, (state, { data }) => ({
    ...state,
    updateEvents: data,
    updateLoading: true,
    updateError: null,
  })),
  on(EventsActions.updateFail, (state, { error }) => ({
    ...state,
    updateLoading: false,
    updateError: error,
    updateEvents: null,
  })),
  on(EventsActions.image, (state) => ({
    ...state,
    image: null,
    imageLoading: false,
    imageError: null,
  })),
  on(EventsActions.imageSuccess, (state, { data }) => ({
    ...state,
    image: data,
    imageLoading: true,
    imageError: null,
  })),
  on(EventsActions.imageFail, (state, { error }) => ({
    ...state,
    image: null,
    imageLoading: false,
    imageError: error,
  })),
);

export const eventsFeatureKey = 'events';
