import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'v-error404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class Error404Component implements OnInit {
  constructor(private titleService: Title) {
    this.titleService.setTitle('404'); 
  }

  ngOnInit() {}
}
