import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { SettingsList } from '../services/settings.service';
import { Tb_Settings } from '../interfaces/settings.interface';

export const SettingsActions = createActionGroup({
  source: 'Settings Component',
  events: {
    list: props<SettingsList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    settings: props<{ id: number }>(),
    settingsSuccess: props<{ data: any }>(),
    settingsFail: props<{ error: any }>(),
    cancelSettings: props<{ id: string }>(),
    cancelSuccess: props<{ data: any }>(),
    cancelFail: props<{ error: any }>(),
    createSettings: props<Partial<Tb_Settings>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    updateSettings: props<Partial<Tb_Settings>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
    syncData: props<{ id: number, value: string }>(),
    syncSuccess: props<{ data: any }>(),
    syncFail: props<{ error: any }>(),
  },
});
