import { createActionGroup, props } from '@ngrx/store';
import { EventsList } from '../services/events.service';
import { EventFormData } from '../interfaces/events.interface';

export const EventsActions = createActionGroup({
  source: 'Events Component',
  events: {
    list: props<EventsList>(),
    listSuccess: props<{ data: any }>(),
    listFail: props<{ error: any }>(),
    event: props<{ id: number | undefined }>(),
    eventSuccess: props<{ data: any }>(),
    eventFail: props<{ error: any }>(),
    create: props<Partial<EventFormData>>(),
    createSuccess: props<{ data: any }>(),
    createFail: props<{ error: any }>(),
    update: props<Partial<EventFormData>>(),
    updateSuccess: props<{ data: any }>(),
    updateFail: props<{ error: any }>(),
    image: props<{
      files: {
        fileType: string;
        file: File;
        index: number;
        name: string;
      }[];
      folder: string;
    }>(),
    imageSuccess: props<{ data: string[] }>(),
    imageFail: props<{ error: any }>(),
  },
});
