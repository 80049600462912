import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { OpportunityActions } from './opportunity.actions';

import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  OpportunityList,
  OpportunityService,
} from '../services/opportunity.service';
import { OpportunityFormData } from '../interfaces/opportunity.interface';
import { of } from 'rxjs';

@Injectable()
export class OpportunityEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.list),
      map(
        ({ type: _type, ...params }: OpportunityList & { type: string }) =>
          params,
      ),
      mergeMap((params) => {
        return this.opportunityService
          .list(params)
          .pipe(map((data) => OpportunityActions.listSuccess({ data })));
      }),
    ),
  );

  getOpportunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.opportunity),
      map(({ type: _type, ...params }: { type: string; id: number, dealType?: number }) => params),
      mergeMap((params) => {
        return this.opportunityService.getOpportunity(params).pipe(
          map((data) => OpportunityActions.opportunitySuccess({ data })),
          catchError((error) =>
            of(OpportunityActions.opportunityFail({ error })),
          ),
        );
      }),
    ),
  );

  categories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.categories),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          limit: number;
          offset: number;
        }) => params,
      ),
      mergeMap((params) => {
        return this.opportunityService
          .categories(params)
          .pipe(map((data) => OpportunityActions.categoriesSuccess({ data })));
      }),
    ),
  );

  suppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.suppliers),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          limit: number;
          offset: number;
          name?: string;
        }) => params,
      ),
      mergeMap((params) => {
        return this.opportunityService
          .suppliers(params)
          .pipe(map((data) => OpportunityActions.suppliersSuccess({ data })));
      }),
    ),
  );

  malls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.malls),
      map(
        ({ type: _type, ...params }: { type: string; name?: string }) => params,
      ),
      mergeMap((params) => {
        return this.opportunityService
          .malls(params)
          .pipe(map((data) => OpportunityActions.mallsSuccess({ data })));
      }),
    ),
  );

  tags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.tags),
      map(
        ({ type: _type, ...params }: { type: string; name?: string }) => params,
      ),
      mergeMap(() => {
        return this.opportunityService
          .tags()
          .pipe(map((data) => OpportunityActions.tagsSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.delete),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.opportunityService
          .delete(param.ids)
          .pipe(map((data) => OpportunityActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<OpportunityFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.opportunityService
          .create(params as Partial<OpportunityFormData>)
          .pipe(map((data) => OpportunityActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<OpportunityFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.opportunityService
          .update(params as Partial<OpportunityFormData>)
          .pipe(map((data) => OpportunityActions.updateSuccess({ data })));
      }),
    ),
  );

  duplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.duplicate),
      map(
        ({
          type: _type,
          ...params
        }: Partial<OpportunityFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.opportunityService
          .duplicate(params as Partial<OpportunityFormData>)
          .pipe(map((data) => OpportunityActions.duplicateSuccess({ data })));
      }),
    ),
  );


  uploadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.image),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          files: {
            type: string;
            file: File;
            name: string;
          }[];
          folder: string;
          galleryId: number;
        }) => params,
      ),
      mergeMap((params) => {
        return this.opportunityService
          .uploadImage(params)
          .pipe(map((data) => OpportunityActions.imageSuccess({ data })));
      }),
    ),
  );

  opportunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityActions.opportunitiesDropdown),
      map(
        ({
          type: _type,
          ...params
        }: {
          type: string;
          limit: number;
          offset: number;
          dealType?: number;
          name?: string
          supplierId?: number
        }) => params,
      ),
      mergeMap((params) => {
        return this.opportunityService
          .opportunities(params)
          .pipe(map((data) => OpportunityActions.opportunitiesDropdownSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private opportunityService: OpportunityService,
  ) {}
}
