import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EntityActions } from './client.actions';

import { map, mergeMap } from 'rxjs/operators';
import { ClientService, TableList } from '../services/client.service';
import { EntityFormData } from '../interfaces/client.interface';

@Injectable()
export class ClientEffects {

  getClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.entity),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.clientService
          .getClient(params)
          .pipe(map((data) => EntityActions.entitySuccess({ data })));
      }),
    ),
  );

  getClientSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.subscriptionEntity),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.clientService
          .getClientSubscription(params)
          .pipe(map((data) => EntityActions.subscriptionEntitySuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<EntityFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.clientService
          .update(params as Partial<EntityFormData>)
          .pipe(map((data) => EntityActions.updateSuccess({ data })));
      }),
    ),
  );

  updateEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EntityActions.updateEntity),
      map(
        ({
          type: _type,
          ...params
        }: Partial<EntityFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.clientService
          .update(params as Partial<EntityFormData>)
          .pipe(map((data) => EntityActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private clientService: ClientService,
  ) {}
}
