import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from '../app/shared/components/loading/loading.component'; // Update this path to your loader component

import Quill from 'quill';

const SnowTheme = Quill.import('themes/snow');

class MyCustomSnowTheme extends SnowTheme {
  
  quill: Quill
  options: unknown
  tooltip: any
  root: unknown
  constructor(quill: Quill, options: unknown) {
    super(quill, options);
    this.quill = quill
    this.options = options
    this.root = super.root
  }

  extendToolbar(toolbar: unknown) {
    super.extendToolbar(toolbar);

    const tooltip = this.tooltip.root;

    const linkElement = tooltip.querySelector('a.ql-preview');

    if (linkElement) {
      const openNewTabCheckbox = document.createElement('input');
      openNewTabCheckbox.type = 'checkbox';
      openNewTabCheckbox.id = 'openInNewTabCheckbox';
      openNewTabCheckbox.style.marginLeft = '10px';
      openNewTabCheckbox.style.display = 'none';

      const label = document.createElement('label');
      label.innerText = 'Open in New Tab';
      label.htmlFor = 'openInNewTabCheckbox';
      label.style.marginLeft = '5px';
      label.style.display = 'none';

      // Create a div for the checkbox and label
      const checkboxDiv = document.createElement('div');
      checkboxDiv.className = 'ql-open-new-tab-checkbox';
      checkboxDiv.style.marginTop = '10px';
      checkboxDiv.appendChild(openNewTabCheckbox);
      checkboxDiv.appendChild(label);
      const linkAttribute = linkElement.getAttribute('target')

      // Append the checkbox div to the tooltip
      tooltip.appendChild(checkboxDiv);

      if (linkAttribute === '_blank') {
        openNewTabCheckbox.checked = true
      } else if (linkAttribute === '_self') {
        openNewTabCheckbox.checked = false
      }

      openNewTabCheckbox.addEventListener('change', () => {
        const href = linkElement.getAttribute('href');
        if (href) {
          const target = openNewTabCheckbox.checked ? '_blank' : '_self';
          linkElement.setAttribute('target', target);
        }
      });

      this.quill.on('selection-change', (range) => {
        if (range && (this.quill.getFormat(range)['link'] || this.quill.getFormat({index: range.index + 1, length: range.length})['link'])){
          openNewTabCheckbox.style.display = 'none';
          label.style.display = 'none';

          const linkAttributeRange = this.quill.getFormat(range)['link'] || this.quill.getFormat({index: range.index + 1, length: range.length})['link']
          const [_linkValue, linkAttributeR] = linkAttributeRange.split(':');
          linkElement.setAttribute('target', linkAttributeR);

          if (linkAttributeR === '_blank') {
            openNewTabCheckbox.checked = true
          } else if (linkAttributeR === '_self') {
            openNewTabCheckbox.checked = false
          }
        } else {
          openNewTabCheckbox.style.display = 'inline';
          label.style.display = 'inline';
        }
      });
    }
  }
}

Quill.register('themes/snow', MyCustomSnowTheme, true);

const BaseLink  = Quill.import('formats/link');

class CustomLink extends BaseLink {
  static create(data: string) {
    const [value, target] = data.split(':');
    const node = super.create(value);
    node.setAttribute('target', target);

    // Set target based on checkbox state in MyCustomSnowTheme
    const checkbox = document.getElementById('openInNewTabCheckbox') as HTMLInputElement;
    const checkBoxStyle = checkbox && checkbox.style.display;

    if (checkBoxStyle === 'inline') {
      const target = checkbox && checkbox.checked ? '_blank' : '_self';
      node.setAttribute('target', target);
    }

    return node;
  }

  static formats(domNode: HTMLElement) {
    return `${domNode.getAttribute('href')}:${domNode.getAttribute('target')}`;
  }

  format(_name: string, value: string) {
    super.format(_name, value);
    
    const checkbox = document.getElementById('openInNewTabCheckbox') as HTMLInputElement;
    const checkBoxStyle = checkbox && checkbox.style.display;
    if (checkBoxStyle === 'inline') {
      const target = checkbox && checkbox.checked ? '_blank' : '_self';
      this['domNode'].setAttribute('target', target);
    }
  }
}

Quill.register('formats/link', CustomLink, true);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',

})
export class AppComponent {
  title = 'MyOfer-CMS';
}
