import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BannerFormData } from '../interfaces/slots.interface';

export interface BannerList {
  offset: number;
  limit: number;
  name?: string;
  id?: string;
  supplier?: number;
}

@Injectable({
  providedIn: 'root',
})
export class BannersService {
  constructor(private http: HttpClient) {}

  public list(params: BannerList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/slots`, {
      params: httpParams,
    });
  }

  public getBanner(params: { id: number }) {
    return this.http.get(
      `${environment.apiUrl}/slots/${params.id}`,
    );
  }

  public delete(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/slots/`, { body: { ids } });
  }

  public create(params: Partial<BannerFormData>) {
    return this.http.post(`${environment.apiUrl}/slots/`, params);
  }

  public update(params: Partial<BannerFormData>) {
    return this.http.put(`${environment.apiUrl}/slots/`, { ...params });
  }
}
