import { Injectable } from '@angular/core';
import { VLayoutService } from '../services/v-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(
    private readonly layoutService: VLayoutService,
    private authService: AuthService,
  ) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    const items: NavigationItem[] = [
      {
        type: 'subheading',
        label: 'דוחות',
        disable: !this.authService.getTokenRole([
          'Admin',
        ]),
        children: [
          {
            type: 'link',
            label: 'MyOfer',
            route: '/dashboards/myofer',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CRM',
            ]),
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'חניה',
        disable: !this.authService.getTokenRole([
          'Admin',
          'Parking',
        ]),
        children: [
          {
            type: 'link',
            label: 'ראד״ק',
            route: '/parking/list',
            disable: !this.authService.getTokenRole([
              'Admin',
            ]),
            icon: 'mat:local_parking',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'מנהל חניון',
            route: '/parking-manager/dashboard',
            disable: !this.authService.getTokenRole([
              'Admin',
            ]),
            icon: 'mat:local_parking',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'Gift Card',
        disable: !this.authService.getTokenRole([
          'Admin',
          'GiftCard',
          'CRM',
        ]),
        children: [
          {
            type: 'link',
            label: 'ניהול',
            route: '/gift-card/list',
            disable: !this.authService.getTokenRole([
              'Admin',
            ]),
            icon: 'mat:card_giftcard',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'נאמנות',
        disable: !this.authService.getTokenRole([
          'Admin',
          'CRM',
        ]),
        children: [
          {
            type: 'link',
            label: 'ניהול חשבוניות',
            route: '/loyalty/receipts',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CRM',
            ]),
            icon: 'mat:receipt',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'עסקאות',
        disable: !this.authService.getTokenRole([
          'Admin',
          'CMS',
        ]),
        children: [
          {
            type: 'link',
            label: 'הטבות',
            route: '/cms/opportunity',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:card_giftcard',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'הטבות בתשלום',
            route: '/cms/flashDeals',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:card_giftcard',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'My sale',
            route: '/cms/saleDeals',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:card_giftcard',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'ניהול תוכן',
        disable: !this.authService.getTokenRole([
          'Admin',
          'CMS',
        ]),
        children: [
          {
            type: 'link',
            label: 'קניונים',
            route: '/cms/malls',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:local_mall',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'Groups',
            route: '/cms/groups',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:supervisor_account',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'רשתות',
            route: '/cms/chains',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:supervisor_account',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'חנויות',
            route: '/cms/stores',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:store_mall_directory',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'אירועים',
            route: '/cms/events',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:store_mall_directory',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'קטגוריות',
            route: '/cms/categories',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:category',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'קופונים',
            route: '/cms/coupons',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:shopping_cart',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'דפי נחיתה',
            route: '/cms/lps',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:shopping_cart',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'באנרים',
            route: '/cms/banners',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:shopping_cart',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'סלוטים',
            route: '/cms/slots',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:shopping_cart',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'דפי תוכן',
            route: '/cms/content-pages',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CMS',
            ]),
            icon: 'mat:article',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'שירות לקוחות',
        disable: !this.authService.getTokenRole([
          'Admin',
          'CRM',
        ]),
        children: [
          {
            type: 'link',
            label: 'Clients',
            route: '/crm/clients',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CRM',
            ]),
            icon: 'mat:supervisor_account',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'Orders',
            route: '/crm/orders',
            disable: !this.authService.getTokenRole([
              'Admin',
              'CRM',
            ]),
            icon: 'mat:store_mall_directory',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'מנהל מערכת',
        disable: !this.authService.getTokenRole([
          'Admin',
        ]),
        children: [
          {
            type: 'link',
            label: 'Users',
            route: '/admin/users',
            disable: !this.authService.getTokenRole([
              'Admin',
            ]),
            icon: 'mat:group_work',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'Permissions',
            route: '/admin/build',
            disable: !this.authService.getTokenRole([
              'Admin',
            ]),
            icon: 'mat:book',
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'הגדרות',
            route: '/admin/settings',
            disable: !this.authService.getTokenRole([
              'Admin',
            ]),
            icon: 'mat:settings',
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
    ];

    this._items.next(items);
  }
}
