import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CategoryFormData } from '../interfaces/category.interface';
import { forkJoin, of, switchMap } from 'rxjs';

export interface CategoryList {
  limit: number;
  offset: number;
  categoryName?: string;
  categoryCode?: string;
  businessUnit?: string;
  active?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  public list(params: CategoryList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/category/`, {
      params: httpParams,
    });
  }

  public saveDealsOrder(payload: { categoryId: number; opportunities: { id: number; order: number }[] }) {
    return this.http.post(
      `${environment.apiUrl}/opportunity/order/byCategory/${payload.categoryId}`,
      { opportunities: payload.opportunities }
    );
  }

  public getCategory(params: { id: number }) {
    return this.http.get(`${environment.apiUrl}/category/id/${params.id}`);
  }

  public getDeal(params: { id: number }){
      return this.http.get(`${environment.apiUrl}/opportunity/${params.id}/name`);
  }

  public getCategoriesChannel() {
    return this.http.get(`${environment.apiUrl}/category/channel`);
  }

  public getCategoriesParents(params: {
    categoryId?: number;
    single?: boolean;
  }) {
    return this.http.get(`${environment.apiUrl}/category/parent`, { params });
  }

  public delete(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/category/`, {
      body: { ids },
    });
  }

  public create(params: Partial<CategoryFormData>) {
    return this.http.post(`${environment.apiUrl}/category/`, params);
  }

  public update(params: Partial<CategoryFormData>) {
    return this.http.put(`${environment.apiUrl}/category/`, { ...params });
  }

  private getSignUrls(params: {
    filesType: { index: number; type: string }[];
    folder: string;
  }) {
    return this.http.post(`${environment.apiUrl}/cloudinary/signUrls`, {
      ...params,
      onlyFolder: true,
    });
  }

  private upload(
    signedFiles: {
      uploadUrl: string;
      file: File;
      api_key: string;
      timestamp: number;
      signature: string;
      relativeLink: string;
    }[],
  ) {
    const uploadObservables = signedFiles.map((signedFile) => {
      const formData = new FormData();
      formData.append('file', signedFile.file);
      formData.append('api_key', signedFile.api_key);
      formData.append('timestamp', String(signedFile.timestamp));
      formData.append('signature', signedFile.signature);
      formData.append('public_id', signedFile.relativeLink);

      return this.http.post(signedFile.uploadUrl, formData);
    });

    return forkJoin(uploadObservables);
  }

  public uploadImage({
    files,
    folder,
  }: {
    files: {
      name: string;
      fileType: string;
      file: File;
      index: number;
    }[];
    folder: string;
  }) {
    return this.getSignUrls({
      filesType: files.map((f) => ({
        index: f.index,
        type: f.fileType,
        name: f.name,
      })),
      folder,
    }).pipe(
      switchMap((res: unknown) => {
        const signedUrls = (
          res as {
            signedUrls: {
              name: string;
              uploadUrl: string;
              relativeLink: string;
              api_key: string;
              signature: string;
              timestamp: number;
            }[];
          }
        ).signedUrls;

        const signedFiles = signedUrls.map((t, index) => {
          const file = new File([files[index].file], t.name, {
            type: files[index].file.type,
          });
          return {
            ...t,
            file,
          };
        });
        return this.upload(signedFiles).pipe(
          switchMap(() => {
            return of(signedUrls.map((t) => t.relativeLink));
          }),
        );
      }),
    );
  }
}
