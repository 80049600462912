import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CategoryFormData } from '../interfaces/category.interface';

export interface CategoryList {
  limit: number;
  offset: number;
  categoryName?: string;
  categoryCode?: string;
  businessUnit?: string;
  active?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  public list(params: CategoryList) {
    const httpParams: HttpParams = new HttpParams({
      fromObject: { ...params },
    });

    return this.http.get(`${environment.apiUrl}/category/`, {
      params: httpParams,
    });
  }

  public saveDealsOrder(payload: { categoryId: number; opportunities: { id: number; order: number }[] }) {
    return this.http.post(
      `${environment.apiUrl}/opportunity/order/byCategory/${payload.categoryId}`,
      { opportunities: payload.opportunities }
    );
  }

  public getCategory(params: { id: number }) {
    return this.http.get(`${environment.apiUrl}/category/id/${params.id}`);
  }

  public getDeal(params: { id: number }){
      return this.http.get(`${environment.apiUrl}/opportunity/${params.id}/name`);
  }

  public getCategoriesChannel() {
    return this.http.get(`${environment.apiUrl}/category/channel`);
  }

  public getCategoriesParents(params: {
    categoryId?: number;
    single?: boolean;
  }) {
    return this.http.get(`${environment.apiUrl}/category/parent`, { params });
  }

  public delete(ids: number[]) {
    return this.http.delete(`${environment.apiUrl}/category/`, {
      body: { ids },
    });
  }

  public create(params: Partial<CategoryFormData>) {
    return this.http.post(`${environment.apiUrl}/category/`, params);
  }

  public update(params: Partial<CategoryFormData>) {
    return this.http.put(`${environment.apiUrl}/category/`, { ...params });
  }
}
