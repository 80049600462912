import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { LpsActions } from './lps.actions';

import { map, mergeMap } from 'rxjs/operators';
import { LpList, LpsService } from '../services/lps.service';
import { LpFormData } from '../interfaces/lps.interface';

@Injectable()
export class LpsEffects {
  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LpsActions.list),
      map(({ type: _type, ...params }: LpList & { type: string }) => params),
      mergeMap((params) => {
        return this.lpsService
          .list(params)
          .pipe(map((data) => LpsActions.listSuccess({ data })));
      }),
    ),
  );

  getLp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LpsActions.lp),
      map(({ type: _type, ...params }: { type: string; id: number }) => params),
      mergeMap((params) => {
        return this.lpsService
          .getLp(params)
          .pipe(map((data) => LpsActions.lpSuccess({ data })));
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LpsActions.delete),
      map(({ ids }: { ids: number[]; type: string }) => ({
        ids,
      })),
      mergeMap((param) => {
        return this.lpsService
          .delete(param.ids)
          .pipe(map((data) => LpsActions.deleteSuccess({ data })));
      }),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LpsActions.create),
      map(
        ({
          type: _type,
          ...params
        }: Partial<LpFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.lpsService
          .create(params as Partial<LpFormData>)
          .pipe(map((data) => LpsActions.createSuccess({ data })));
      }),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LpsActions.update),
      map(
        ({
          type: _type,
          ...params
        }: Partial<LpFormData> & { type: string }) => ({
          params,
        }),
      ),
      mergeMap((params) => {
        return this.lpsService
          .update(params as Partial<LpFormData>)
          .pipe(map((data) => LpsActions.updateSuccess({ data })));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private lpsService: LpsService,
  ) {}
}
