import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideRouter, withComponentInputBinding } from '@angular/router';

// http client
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
  HttpClient,
} from '@angular/common/http';

import { importProvidersFrom, isDevMode } from '@angular/core';
//Store
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

//reducer
import {
  counterFeatureKey,
  counterReducer,
} from './pages/pages/counter/store/counter.reducer';
import {
  articleFeatureKey,
  articleReducer,
} from './pages/pages/article/store/article.reducer';

//Effect
import { provideEffects } from '@ngrx/effects';
import * as articleEffects from './pages/pages/article/store/article.effects';
import { CounterEffects } from './pages/pages/counter/store/counter.effects';

//material
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { routes } from './app.routes';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideV } from './shared/core/v.provider';
import { vConfigs } from './shared/core/config/v-configs';
import { provideNavigation } from './shared/core/navigation/navigation.provider';
import { provideIcons } from './shared/core/icons/icons.provider';
import { provideLuxon } from './shared/core/luxon/luxon.provider';
import { provideQuillConfig } from 'ngx-quill';
import {
  registerFeatureKey,
  registerReducer,
} from './pages/pages/auth/register/store/register.reducer';
import { RegisterEffects } from './pages/pages/auth/register/store/register.effects';
import {
  loginFeatureKey,
  loginReducer,
} from './pages/pages/auth/login/store/login.reducer';
import { LoginEffects } from './pages/pages/auth/login/store/login.effects';
import { MallsEffects } from './pages/apps/cms/malls/store/malls.effects';
import {
  mallsFeatureKey,
  mallsReducer,
} from './pages/apps/cms/malls/store/malls.reducer';
import { AuthInterceptor } from './shared/core/auth/auth.interceptor';
import { environment } from 'src/environments/environment';
import {
  clientsKey,
  clientsReducer,
} from './pages/apps/crm/clients/store/clients.reducer';
import { ClientsEffects } from './pages/apps/crm/clients/store/clients.effects';
import {
  ordersKey,
  ordersReducer,
} from './pages/apps/crm/orders/store/orders.reducer';
import { OrdersEffects } from './pages/apps/crm/orders/store/orders.effects';
import {
  clientKey,
  clientReducer,
} from './pages/apps/crm/client/store/client.reducer';
import { ClientEffects } from './pages/apps/crm/client/store/client.effects';
import {
  couponKey,
  couponReducer,
} from './pages/apps/coupons/store/coupons.reducer';
import { CouponsEffects } from './pages/apps/coupons/store/coupons.effects';
import {
  dashboardKey,
  dashboardReducer,
} from './pages/dashboards/dashboard-radc/store/dashboard.reducer';
import { DashboardEffects } from './pages/dashboards/dashboard-radc/store/dashboard.effects';
import { reducers } from './app.state';
import { LoadingInterceptor } from '../app/shared/components/loading/loading.interceptor';

const CALLBACK_NAME = 'initMap';

export enum GoogleMapsLibraries {
  drawing = 'drawing',
  geometry = 'geometry',
  localContext = 'localContext',
  places = 'places',
  visualization = 'visualization',
}

export function loadGoogleMaps(
  googleMapsKey: string,
  libraries: GoogleMapsLibraries[] = [],
) {
  if (!window) {
    return Promise.resolve();
  }

  return new Promise<void>((resolve, reject) => {
    function onError(err?: any) {
      clearTimeout(timeoutId);
      (window as any)[CALLBACK_NAME] = () => {};
      scriptElement.removeEventListener('error', onError);
      reject(err || new Error('Could not load the Google Maps API'));
    }

    const CALLBACK_NAME = 'initMap';
    const timeoutId = setTimeout(() => onError(), 10000);

    if ((window as any)[CALLBACK_NAME]) {
      // Callback function already exists, resolve the promise immediately
      clearTimeout(timeoutId);
      resolve();
      return;
    }

    (window as any)[CALLBACK_NAME] = () => {
      clearTimeout(timeoutId);
      scriptElement.removeEventListener('error', onError);
      resolve();
      delete (window as any)[CALLBACK_NAME];
    };

    libraries = [...new Set(libraries)];

    const scriptElement = document.createElement('script');
    scriptElement.addEventListener('error', onError);
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&region=il&language=he&callback=${CALLBACK_NAME}&libraries=${libraries.join(',')}`;
    document.head.appendChild(scriptElement);
  });
}
import { Observable, catchError, of, tap } from 'rxjs';
import { CategoryEffects } from './pages/apps/cms/categories/store/category.effects';
import {
  categoryFeatureKey,
  categoryReducer,
} from './pages/apps/cms/categories/store/category.reducer';
import { OpportunityEffects } from './pages/apps/opportunity/store/opportunity.effects';
import {
  opportunityFeatureKey,
  opportunityReducer,
} from './pages/apps/opportunity/store/opportunity.reducer';
import {
  chainsFeatureKey,
  chainsReducer,
} from './pages/apps/cms/chains/store/chains.reducer';
import { ChainsEffects } from './pages/apps/cms/chains/store/chains.effects';
import {
  groupsFeatureKey,
  groupsReducer,
} from './pages/apps/cms/groups/store/groups.reducer';
import { GroupsEffects } from './pages/apps/cms/groups/store/groups.effects';
import {
  lpsFeatureKey,
  lpsReducer,
} from './pages/apps/cms/lps/store/lps.reducer';
import { LpsEffects } from './pages/apps/cms/lps/store/lps.effects';
import {
  bannersFeatureKey,
  bannersReducer,
} from './pages/apps/banners/store/banners.reducer';
import { BannersEffects } from './pages/apps/banners/store/banners.effects';
import {
  bannersFeatureKey as slotsFeatureKey,
  bannersReducer as slotsFeatureReducer,
} from './pages/apps/cms/slots/store/slots.reducer';
import { BannersEffects as SlotsEffects } from './pages/apps/cms/slots/store/slots.effects';
import {
  usersFeatureKey,
  usersReducer,
} from './pages/apps/admin/users/store/users.reducer';
import { UsersEffects } from './pages/apps/admin/users/store/users.effects';
import {
  parkingFeatureKey,
  parkingReducer,
} from './pages/apps/parking/store/parking.reducer';
import { ParkingEffects } from './pages/apps/parking/store/parking.effects';
import {
  parkingManagerKey,
  parkingManagerReducer,
} from './pages/apps/parkingManager/store/parking.reducer';
import { ParkingManagerEffects } from './pages/apps/parkingManager/store/parking.effects';
import {
  storesFeatureKey,
  storesReducer,
} from './pages/apps/cms/stores/store/stores.reducer';
import { StoresEffects } from './pages/apps/cms/stores/store/stores.effects';
export function setDictionary(httpClient: HttpClient): () => Observable<any> {
  return () =>
    httpClient.get(`${environment.apiUrl}/unicorn/dictionary`).pipe(
      tap((result) => {
        if (result) {
          localStorage.setItem('dictionary', JSON.stringify(result));
        }
      }),
      catchError((err) => {
        console.log('There is an error', err);
        return of(null);
      }),
    );
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor, // Add this line
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useValue: () =>
        loadGoogleMaps(environment.googleApiKey, [GoogleMapsLibraries.places]),
      multi: true,
      deps: [HttpClient],
    },
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(),
    {
      provide: APP_INITIALIZER,
      useFactory: setDictionary,
      multi: true,
      deps: [HttpClient],
    },
    provideStore({ router: routerReducer }),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    provideStore(reducers),
    provideState(counterFeatureKey, counterReducer),
    provideState(articleFeatureKey, articleReducer),
    provideState(registerFeatureKey, registerReducer),
    provideState(loginFeatureKey, loginReducer),

    provideState(parkingFeatureKey, parkingReducer),
    provideState(parkingManagerKey, parkingManagerReducer),
    provideState(mallsFeatureKey, mallsReducer),
    provideState(categoryFeatureKey, categoryReducer),
    provideState(opportunityFeatureKey, opportunityReducer),
    provideState(chainsFeatureKey, chainsReducer),
    provideState(groupsFeatureKey, groupsReducer),
    provideState(lpsFeatureKey, lpsReducer),
    provideState(usersFeatureKey, usersReducer),
    provideState(storesFeatureKey, storesReducer),
    provideState(bannersFeatureKey, bannersReducer),
    provideState(slotsFeatureKey, slotsFeatureReducer),

    provideState(dashboardKey, dashboardReducer),
    provideState(clientKey, clientReducer),
    provideState(ordersKey, ordersReducer),
    provideState(clientsKey, clientsReducer),
    provideState(couponKey, couponReducer),

    provideEffects(articleEffects),
    provideEffects(CounterEffects),
    provideEffects(RegisterEffects),
    provideEffects(LoginEffects),
    provideEffects(ParkingEffects),
    provideEffects(ParkingManagerEffects),
    provideEffects(MallsEffects),
    provideEffects(ClientEffects),
    provideEffects(ClientsEffects),
    provideEffects(OrdersEffects),
    provideEffects(DashboardEffects),
    provideEffects(CouponsEffects),
    provideEffects(CategoryEffects),
    provideEffects(OpportunityEffects),
    provideEffects(UsersEffects),
    provideEffects(ChainsEffects),
    provideEffects(GroupsEffects),
    provideEffects(LpsEffects),
    provideEffects(StoresEffects),
    provideEffects(BannersEffects),
    provideEffects(SlotsEffects),

    provideRouterStore(),
    importProvidersFrom(
      BrowserModule,
      MatDialogModule,
      MatBottomSheetModule,
      MatNativeDateModule,
      BrowserAnimationsModule,
      NoopAnimationsModule,
    ),
    provideAnimations(),

    provideV({
      /**
       * The config that will be used by default.
       * This can be changed at runtime via the config panel or using the VConfigService.
       */
      config: vConfigs.poseidon,
      /**
       * Only themes that are available in the config in tailwind.config.ts should be listed here.
       * Any theme not listed here will not be available in the config panel.
       */
      availableThemes: [
        {
          name: 'Default',
          className: 'v-theme-default',
        },
        {
          name: 'Teal',
          className: 'v-theme-teal',
        },
        {
          name: 'Green',
          className: 'v-theme-green',
        },
        {
          name: 'Purple',
          className: 'v-theme-purple',
        },
        {
          name: 'Red',
          className: 'v-theme-red',
        },
        {
          name: 'Orange',
          className: 'v-theme-orange',
        },
      ],
    }),
    provideNavigation(),
    provideIcons(),
    provideLuxon(),
    provideQuillConfig({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['clean'],
          ['link', 'image'],
        ],
      },
    }),
  ],
};
